import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import { setAlert } from "../../../../actions/alert";
import { updateLocation } from "../../../../actions/currentLocation";
import { updateRestaurant } from "../../../../actions/restaurantBuilder";
import { getRestaurantCuisines } from "../../../../util/getRestaurantCuisines";
import CheckUnauth from "../../../auth/CheckUnauth";
import CuisinePicker from "../../../common/CuisinePicker";
import { PanelContainer } from "../../administration/AddGoogleIDs";
import MenuItems from "./shared/MenuItems";
const RestaurantCuisine = ({
  setAlert,
  updateRestaurant,
  currRestaurant,
  updateLocation,
  ...props
}) => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    cuisines: [],
    predictedOptions: { 0: [], 1: [] },
  });
  const [loadingVeganOptions, setLoadingVeganOptions] = useState(true);
  const [selectedVeganOptions, setSelectedVeganOptions] = useState({});
  const [deselectedVeganOptions, setDeselectedVeganOptions] = useState({});
  const [otherCuisineText, setOtherCuisineText] = useState("");
  useEffect(() => {
    if (props.location.state) {
      const { name, address, geoJson, predictedOptions, status, googleId } =
        props.location.state;
      setFormData((oldData) => ({
        ...oldData,
        name,
        address,
        geoJson,
        predictedOptions,
        status,
        googleId,
      }));
    }
  }, [props.location.state]);

  const { cuisines, predictedOptions, googleId } = formData;
  useEffect(() => {
    async function getOptions() {
      const { website } = (
        await axios.get(`/api/restaurants/website/${googleId}`)
      ).data;
      if (website) {
        const url = website.replace(/(^\w+:|^)\/\//, "");
        try {
          console.log(encodeURI(url));
          console.log(encodeURIComponent(url));
          const rest = await axios.get(
            `/api/restaurant_builder/${encodeURIComponent(url)}`
          );
          const { predictedOptions, geoJson } = rest.data;
          if (predictedOptions) {
            console.log(predictedOptions);
            setFormData((data) => ({ ...data, predictedOptions }));
          } else if (!predictedOptions) {
            history.push({
              pathname: "/create/v2/cuisine/manual",
              state: { ...props.location.state },
            });
          }
          if (geoJson) {
            setFormData((data) => ({ ...data, geoJson }));
          }
        } catch (e) {
          console.log(e);
          history.push({
            pathname: "/create/v2/cuisine/manual",
            state: { ...props.location.state },
          });
        }
      } else {
        history.push({
          pathname: "/create/v2/cuisine/manual",
          state: { ...props.location.state },
        });
      }
      setLoadingVeganOptions(false);
    }

    if (!predictedOptions && googleId) {
      setLoadingVeganOptions(true);
      getOptions();
    } else if (!predictedOptions && !googleId) {
      history.push({
        pathname: "/create/v2/cuisine/manual",
        state: { ...props.location.state },
      });
    }
  }, [googleId, history, predictedOptions, props.location.state]);

  useEffect(() => {
    if (
      predictedOptions &&
      (predictedOptions["0"]?.length > 0 || predictedOptions["1"]?.length > 0)
    ) {
      console.log("staying");
      setLoadingVeganOptions(false);
    }
  }, [predictedOptions]);
  console.log(props.location.state);
  useEffect(() => {
    async function fetchCuisines() {
      const response = await getRestaurantCuisines();
      setFormData((formData) => ({ ...formData, cuisines: response }));
    }
    fetchCuisines();
  }, []);

  const [selectedCuisines, updateSelectedCuisines] = useState({});

  const selectOption = (e) => {
    if (
      e.target.checked &&
      !deselectedVeganOptions.hasOwnProperty(e.target.name)
    ) {
      console.log(e.target.value);
      setSelectedVeganOptions({
        ...selectedVeganOptions,
        [e.target.name]: { [e.target.name]: e.target.value },
      });
    } else {
      const { [e.target.name]: removed, ...rest } = selectedVeganOptions;
      setSelectedVeganOptions(rest);
    }
    console.log(selectedVeganOptions);
  };

  const deselectOption = (e, name, description) => {
    if (
      !deselectedVeganOptions.hasOwnProperty(name) &&
      !selectedVeganOptions.hasOwnProperty(name)
    ) {
      setDeselectedVeganOptions({
        ...deselectedVeganOptions,
        [name]: { [name]: description },
      });
    } else {
      const { [name]: removed, ...rest } = deselectedVeganOptions;
      setDeselectedVeganOptions(rest);
    }
    console.log(deselectedVeganOptions);
  };

  const constructRestaurant = () => {
    const veganOptions = Object.values(selectedVeganOptions).map((item) => {
      return {
        option: Object.keys(item)[0],
        description: Object.values(item)[0],
      };
    });
    return {
      name: formData.name,
      cuisine: Object.values(
        selectedCuisines.other
          ? {
              ...selectedCuisines,
              other: otherCuisineText.toLowerCase().trim(),
            }
          : selectedCuisines
      ),
      location: `${formData.geoJson.coordinates[1]},${formData.geoJson.coordinates[0]}`,
      metadata: { isGoogle: false },
      veganOptions,
      status: formData.status || "RESTAURANT",
    };
  };

  const createRestaurant = async (restaurant) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(restaurant);
    try {
      const response = await axios.post("/api/restaurants", body, config);
      console.log(response.data);
      setAlert("Restaurant successfully created", "success");
    } catch (err) {
      console.log(err.response);
      setAlert(
        err.response.data.errors
          ? err.response.data.errors[0].msg
          : "Error creating restaurant, please try again",
        "failure"
      );
      console.log(err.response.data.errors);
    }
  };
  // if (!predictedOptions) {
  //   history.push({
  //     pathname: "/create/v2/cuisine/manual",
  //     state: { ...props.location.state },
  //   });
  // }
  if (loadingVeganOptions) {
    return (
      <div className="create-container">
        <BarLoader
          loading={loadingVeganOptions}
          color="green"
          height="8px"
          cssOverride={{ margin: "16px" }}
        />
      </div>
    );
  }
  return (
    <div className="container h-100 ">
      <CheckUnauth {...props.location} />
      <div className="row create-container">
        <form
          className="form-container"
          onSubmit={(e) => {
            e.preventDefault();
            createRestaurant(constructRestaurant());
          }}
        >
          <div className="h3" style={{ fontSize: "2em" }}>
            Create a Restaurant
          </div>
          <div style={{ width: "75vw" }}>
            <label className="form-label">Restaurant Cuisine*</label>
            <div style={{ display: "flex" }}>
              <CuisinePicker
                style={{ "min-width": "200px" }}
                className={"form-control noFlex"}
                passedCuisines={cuisines}
                selectedCuisines={selectedCuisines}
                otherCuisineText={otherCuisineText}
                updateSelectedCuisines={updateSelectedCuisines}
                setOtherCuisineText={setOtherCuisineText}
              />
            </div>
          </div>
          <div style={{ width: "75vw" }}>
            <label className="form-label">Menu*</label>
            {predictedOptions && (
              <Fragment>
                <h5>These options are likely to be vegan</h5>
                <MenuItems
                  options={predictedOptions[1]}
                  selectOption={selectOption}
                  deselectOption={deselectOption}
                  selectedVeganOptions={selectedVeganOptions}
                  deselectedVeganOptions={deselectedVeganOptions}
                ></MenuItems>
                <h5>These options are likely NOT to be vegan</h5>
                <MenuItems
                  options={predictedOptions[0]}
                  selectOption={selectOption}
                  deselectOption={deselectOption}
                  selectedVeganOptions={selectedVeganOptions}
                  deselectedVeganOptions={deselectedVeganOptions}
                ></MenuItems>
              </Fragment>
            )}
          </div>
          <PanelContainer
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <input
              style={{ alignSelf: "center", fontSize: "2em", height: "2em" }}
              className="btn-primary"
              type="submit"
              value="Create Restaurant"
            />
            <Link
              to={{
                pathname: "/create/v2/cuisine/manual",
                state: { ...props.location.state },
              }}
            >
              Edit menu manually instead
            </Link>
          </PanelContainer>
        </form>
      </div>
    </div>
  );
};

export default connect((state) => ({}), {
  updateRestaurant,
  updateLocation,
  setAlert,
})(RestaurantCuisine);
