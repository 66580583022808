import GoogleMapReact from "google-map-react";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import greenv from "../../../green-v-md.png";
import { SpaceApartDiv } from "../../restaurantBuilderLegacy/RestaurantCreateBuild";
const LocationPickerWidget = ({
  isDisabled,
  name,
  setFormData,
  unsetGeoJson,
  submitCallback,
  defaultZoom,
  submitButtonText,
  ...props
}) => {
  return (
    <Fragment>
      <SpaceApartDiv style={{ aspectRatio: "1/1", position: "relative" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCHTvWtg42OxUZ6oyN0YeM0Oyy8oCRHFxs",
          }}
          center={{
            lat: unsetGeoJson.coordinates[1],
            lng: unsetGeoJson.coordinates[0],
          }}
          google={undefined}
          defaultZoom={defaultZoom || 12}
          options={{ gestureHandling: "greedy" }}
          onChange={({ center }) => {
            console.log("changing");
            setFormData((old) => ({
              ...old,
              unsetGeoJson: {
                coordinates: [center.lng, center.lat],
              },
            }));
          }}
        ></GoogleMapReact>
        <div>
          <img
            // {...props}
            title={name}
            name={name}
            src={greenv}
            alt=""
            width="32"
            style={{
              position: "absolute",
              left: "calc(50% - 16px)",
              bottom: "50%",
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "calc(50% + 16px)",
              top: "calc(50% - 32px)",
            }}
            className="map-marker-tag"
          >
            {name?.length >= 16 ? name.slice(0, 14) + "..." : name}
          </div>
        </div>
      </SpaceApartDiv>
      <input
        style={{
          textAlign: "center",
          alignSelf: "center",
          fontSize: "1.5em",
          height: "revert",
        }}
        disabled={isDisabled}
        onClick={submitCallback}
        className="btn-primary"
        type="button"
        value={submitButtonText || "Get Address for Location"}
      />
    </Fragment>
  );
};
export default connect((state) => {}, {})(LocationPickerWidget);
