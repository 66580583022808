import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { backendLog } from "../../../util/logging";
import SignupWidget from "../administration/SignupWidget";
import { CenterDiv, FlexContainer } from "../administration/SimplePage";

const Explore = ({ token, ...props }) => {
  useEffect(() => {
    backendLog("User visited the explore page");
  });

  return (
    <FlexContainer>
      <Helmet>
        <title>VegifyIt | Explore</title>
        <meta
          name="description"
          content="
        Discover the towns and metros that have entries in VegifyIt. Use this page to help find your next vegan-friendly dish or restaurant near or far! 
        "
        />
      </Helmet>{" "}
      <CenterDiv>
        <h2>Explore</h2>

        <p>
          If VegifyIt doesn't have restaurants in your area yet, feel free to
          explore some of our supported cities!
        </p>

        <h4>Arizona</h4>
        <ul>
          <p>
            {/* <a href="/?lat=33.4886799&amp;lng=-112.345197">Litchfield Park</a> */}
            <a href="/cities/arizona/litchfield_park">Litchfield Park</a>
          </p>
          <p>
            {/* <a href="/?lat=33.395490271902474&amp;lng=-111.84538907321105">
              Mesa
            </a> */}
            <a href="/cities/arizona/mesa">Mesa</a>
          </p>
          <p>
            {/* <a href="/?lat=33.48542652659076&amp;lng=-112.06845093556713">
              Phoenix
            </a> */}
            <a href="/cities/arizona/phoenix">Phoenix</a>
          </p>
        </ul>
        <h4>California</h4>
        <ul>
          <p>
            <a href="/cities/california/oakland">Oakland</a>
          </p>
          <p>
            <a href="/cities/california/san_francisco">San Francisco</a>
          </p>
        </ul>

        <h4>Connecticut</h4>
        <ul>
          <p>
            <a href="/cities/connecticut/hartford">Hartford</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/connecticut/middletown">Middletown</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/connecticut/new_haven">New Haven</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/connecticut/wallingford">Wallingford</a>
          </p>
        </ul>

        <h4>Massachusetts</h4>
        <ul>
          <p>
            <a href="/cities/massachusetts/boston">Boston</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/cape_cod?zoom=10">Cape Cod</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/east_weymouth">East Weymouth</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/green_harbor-cedar_crest">
              Green Harbor-Cedar Crest
            </a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/salem">Salem</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/sharon">Sharon</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/somerville">Somerville</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/springfield">Springfield</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/weymouth">Weymouth</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/whitinsville">Whitinsville</a>
          </p>
        </ul>
        <ul>
          <p>
            <a href="/cities/massachusetts/worcester">Worcester</a>
          </p>
        </ul>
        <h4>New Hampshire</h4>
        <ul>
          <p>
            <a href="/cities/new_hampshire/concord">Concord</a>
          </p>
          <p>
            {/* <a href="/?lat=40.7439905&amp;lng=-74.0323626">Hoboken</a> */}
            <a href="/cities/new_hampshire/manchester">Manchester</a>
          </p>
          <p>
            <a href="/cities/new_hampshire/nashua">Nashua</a>
          </p>
        </ul>
        <h4>New Jersey</h4>
        <ul>
          <p>
            {/* <a href="/?lat=40.7439905&amp;lng=-74.0323626">Hoboken</a> */}
            <a href="/cities/new_jersey/hoboken">Hoboken</a>
          </p>
          <p>
            <a href="/cities/new_jersey/ridgewood">Ridgewood</a>
          </p>
        </ul>
        <h4>New York</h4>
        <ul>
          <p>
            <a href="/cities/new_york/new_york">New York City</a>
          </p>
          <p>
            <a href="/cities/new_york/buffalo">Buffalo</a>
          </p>
          <p>
            <a href="/cities/new_york/syracuse">Syracuse</a>
          </p>
        </ul>

        <h4>Pennsylvania</h4>
        <ul>
          <p>
            <a href="/cities/pennsylvania/danville">Danville</a>
          </p>
        </ul>

        <h4>Rhode Island</h4>
        <ul>
          <p>
            <a href="/cities/rhode_island/providence">Providence</a>
          </p>
        </ul>

        <h4>Virginia</h4>
        <ul>
          <p>
            <a href="/cities/virginia/arlington">Arlington</a>
          </p>
        </ul>

        <h4>Washington, DC</h4>
        <ul>
          <p>
            <a href="/cities/district_of_columbia/washington">Washington, DC</a>
          </p>
        </ul>
        <div style={{ border: "2px solid black", padding: "16px" }}>
          <p>
            If you want to see your city here or you want to get involved with
            VegifyIt, please sign up for our interest list below!
          </p>
          <SignupWidget callToAction={"Sign Up"} />
        </div>
      </CenterDiv>
    </FlexContainer>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, {})(Explore);
