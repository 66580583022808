import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
const CheckUnauth = ({ token, pathname }) => {
  return (
    <Fragment>
      {!token && <Redirect to={{ pathname: "/login", state: pathname }} />}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, {})(CheckUnauth);
