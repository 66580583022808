import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRestaurantCuisines } from "../../../../util/getRestaurantCuisines";
import CuisinePicker from "../../../common/CuisinePicker";
import { SpaceApartDiv } from "./RestaurantCreateBuild";

const RestaurantEditForm = ({
  currRestaurant,
  submitBackendCall,
  displayText,
}) => {
  const initialFormData = {
    arrayVeganOptions:
      currRestaurant && currRestaurant.veganOptions
        ? currRestaurant.veganOptions
        : [{}],
    cuisines: [],
    restaurantName: currRestaurant.name,
  };

  const [otherCuisineText, setOtherCuisineText] = useState("");

  const [formData, setFormData] = useState(initialFormData);
  const [selectedCuisines, updateSelectedCuisines] = useState(
    currRestaurant && currRestaurant.veganOptions
      ? currRestaurant.cuisine.reduce((a, v) => ({ ...a, [v]: v }), {})
      : {}
  );

  useEffect(() => {
    async function fetchCuisines() {
      const response = await getRestaurantCuisines();
      setFormData((formData) => ({ ...formData, cuisines: response }));
    }
    fetchCuisines();
  }, []);
  const { cuisines, arrayVeganOptions, restaurantName } = formData;

  const updateVeganOptions = (e, id) => {
    setFormData({
      ...formData,
      arrayVeganOptions: arrayVeganOptions.map((option, subId) => {
        if (id === subId) {
          return { ...option, [e.target.name]: e.target.value };
        } else return option;
      }),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting restaurant form");

    const fixedCuisines = selectedCuisines.other
      ? { ...selectedCuisines, other: otherCuisineText.toLowerCase().trim() }
      : selectedCuisines;
    console.log(fixedCuisines);
    const restaurant = {
      name: restaurantName,
      location: currRestaurant.coordinates,
      googleId: currRestaurant.googleId,
      veganOptions: arrayVeganOptions.map((option) => ({
        option: option.option,
        description: option.description,
      })),
      cuisine: Object.values(fixedCuisines),
      metadata: currRestaurant.metadata
        ? currRestaurant.metadata
        : { isGoogle: true },
      status: currRestaurant.status || "RESTAURANT",
    };
    submitBackendCall(restaurant);
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="form-container">
        <div>
          <label className="form-label">Restaurant Name*</label>
          <input
            type="text"
            className="text-form"
            name="name"
            value={restaurantName}
            onChange={(e) =>
              setFormData({ ...formData, restaurantName: e.target.value })
            }
            required
          />
        </div>
        {currRestaurant.address && (
          <div>
            <label className="form-label">Address</label>
            <input
              type="text"
              className="text-form"
              readOnly={true}
              name="address"
              value={currRestaurant.address}
            />
          </div>
        )}
        <hr />
        {/* <div> */}
        <label className="form-label">Vegan Options*</label>
        {arrayVeganOptions.map((option, id) => (
          <div key={id}>
            <SpaceApartDiv>
              <label className="form-label-inline">Name</label>
              <input
                type="text"
                className="text-form"
                name="option"
                value={option.option || ""}
                onChange={(e) => updateVeganOptions(e, id)}
                required
              />
            </SpaceApartDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Desc</label>
              <textarea
                type="text"
                className="text-form"
                name="description"
                value={option.description || ""}
                onChange={(e) => updateVeganOptions(e, id)}
                required
              />
            </SpaceApartDiv>

            <SpaceApartDiv>
              {" "}
              {/* global Tesseract*/}
              {/* <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const worker = new Tesseract.TesseractWorker();
                  worker
                    .recognize(e.target.files[0])
                    .progress((data) => {
                      const event = {
                        target: {
                          name: "desc",
                          value: data.status + " " + data.progress,
                        },
                      };
                      updateVeganOptions(event, id);
                    })
                    .then((data) => {
                      console.log(data);
                      const event = {
                        target: {
                          name: "desc",
                          value: data.text,
                        },
                      };
                      worker.terminate();
                      updateVeganOptions(event, id);
                    });
                }}
              /> */}
              <span
                onClick={(e) => {
                  setFormData({
                    ...formData,
                    arrayVeganOptions: arrayVeganOptions.filter(
                      (unused, subId) => {
                        return subId !== id;
                      }
                    ),
                  });
                }}
              >
                <i className="fa fa-trash" aria-hidden="true" />
              </span>
            </SpaceApartDiv>
          </div>
        ))}
        {arrayVeganOptions.length < 5 && (
          <input
            type="button"
            className="btn-primary"
            value="Add an option"
            onClick={() =>
              setFormData({
                ...formData,
                arrayVeganOptions: [...arrayVeganOptions, {}],
              })
            }
          />
        )}
        {/* </div> */}
        <hr />
        <label className="form-label">Cuisine</label>
        <CuisinePicker
          style={{ "min-width": "200px" }}
          className={"form-control noFlex"}
          passedCuisines={cuisines}
          selectedCuisines={selectedCuisines}
          otherCuisineText={otherCuisineText}
          updateSelectedCuisines={updateSelectedCuisines}
          setOtherCuisineText={setOtherCuisineText}
        />

        <input
          className="btn-primary"
          type="submit"
          value={displayText ? displayText : "Create restaurant"}
        />
      </div>
    </form>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(RestaurantEditForm);
