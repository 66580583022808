import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const Footer = ({ view }) => {
  const location = useLocation();
  const locationIsRoot =
    location.pathname === "/" || location.pathname.includes("/cities");
  const footerClasses = classNames("footer", {
    open: view === "LIST_VIEW" && locationIsRoot,
    closed: view === "MAP_VIEW" && locationIsRoot,
  });

  return (
    <footer className={footerClasses}>
      <p>
        &copy; {new Date().getFullYear()} VegifyIt
        {" | "}
        <a style={{ color: "white" }} href="/about">
          About
        </a>
        {" | "}
        <a style={{ color: "white" }} href="/contact">
          Contact
        </a>
        {" | "}
        <a style={{ color: "white" }} href="/events">
          Events
        </a>
      </p>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  view: state.results.view,
});
export default connect(mapStateToProps, {})(Footer);
