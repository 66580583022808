import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const DropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  z-index: 200;
  left: 0;
  right: 0;
  ${(props) => {
    if (!props.openMenu) {
      return `
        display: none;
        `;
    }
  }};
`;
const DropdownFilter = styled.input`
  display: block, @media only screen and (min-width: 600px) {
    max-width: 200px;
  }
`;
const CuisinePicker = ({
  passedCuisines,
  selectedCuisines,
  updateSelectedCuisines,
  otherCuisineText,
  setOtherCuisineText,
  className,
}) => {
  const [cuisines, setCuisines] = useState(passedCuisines || []);
  useEffect(() => {
    setCuisines(passedCuisines);
  }, [passedCuisines]);
  const onCheckChange = (e) => {
    const cuisineName = e.target.value;
    if (e.target.checked) {
      updateSelectedCuisines({
        ...selectedCuisines,
        [cuisineName]: cuisineName,
      });
      if (e.target.name === "other") {
        setCuisines([...new Set([...cuisines, cuisineName])]);
        setOtherCuisineText("");
      }
    } else {
      const { [cuisineName]: removed, ...rest } = selectedCuisines;
      updateSelectedCuisines(rest);
    }
  };
  const [open, setOpen] = useState(false);

  const myContainer = useRef(null);

  const blur = (e) => {
    if (e.relatedTarget && myContainer.current.contains(e.relatedTarget)) {
      return;
    }
    setOpen(false);
  };

  cuisines.sort((first, second) => {
    if (
      selectedCuisines.hasOwnProperty(first) &&
      !selectedCuisines.hasOwnProperty(second)
    ) {
      return -1;
    } else if (
      selectedCuisines.hasOwnProperty(second) &&
      !selectedCuisines.hasOwnProperty(first)
    ) {
      return 1;
    } else {
      return first.localeCompare(second);
    }
  });

  return (
    <div ref={myContainer} style={{ display: "block" }} className={className}>
      <DropdownFilter
        aria-label="filterCuisines"
        title="Filter the cuisines"
        type="text"
        placeholder="Filter cuisines"
        pattern="[a-zA-Z\-]+"
        value={otherCuisineText}
        name="cuisineFilter"
        onChange={(e) => setOtherCuisineText(e.target.value)}
        onFocus={() => setOpen(true)}
        onBlur={blur}
        className={"flex-text"}
      ></DropdownFilter>
      <div
        style={{
          position: "relative",
          backgroundColor: "white",
          textAlign: "left",
          padding: selectedCuisines.length ? "4px 0px" : "0px",
          zIndex: "200",
          display: "block",
          color: "black",
        }}
      >
        {Object.values(selectedCuisines)
          .map((cuisine) => cuisine.charAt(0).toUpperCase() + cuisine.slice(1))
          .join(", ")}
        <DropdownMenu
          className="checkbox-grid"
          openMenu={open}
          tabIndex={-1}
          onBlur={blur}
        >
          <ul style={{ color: "black" }}>
            {cuisines
              .filter(
                (cuisine) =>
                  cuisine.startsWith(otherCuisineText.toLowerCase()) ||
                  selectedCuisines[cuisine]
              )
              .map((cuisine) => (
                <li key={cuisine}>
                  <label>
                    <input
                      name={cuisine}
                      value={cuisine}
                      type="checkbox"
                      onChange={(e) => onCheckChange(e)}
                      checked={selectedCuisines.hasOwnProperty(cuisine)}
                    />
                    {cuisine}
                  </label>
                </li>
              ))}

            <li>
              <label style={{ display: "flex", "flex-wrap": "nowrap" }}>
                <input
                  name="other"
                  value={otherCuisineText}
                  type="checkbox"
                  onChange={(e) => onCheckChange(e)}
                  checked={selectedCuisines.hasOwnProperty(otherCuisineText)}
                />
                <input
                  title="A cuisine with no spaces, commas, or &amp;"
                  type="text"
                  // className="text-form"
                  placeholder="Other"
                  pattern="[a-zA-Z\-]+"
                  value={otherCuisineText}
                  name="otherCuisineText"
                  onChange={(e) => setOtherCuisineText(e.target.value)}
                />
              </label>
            </li>
          </ul>
        </DropdownMenu>
      </div>
    </div>
  );
};
export default CuisinePicker;
