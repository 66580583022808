import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { resetNoData } from "../../../actions/restaurants";
import { LIST_VIEW } from "../../../actions/types";
import Restaurant from "./Restaurant";

const ResultSideBody = ({ shouldShow, noData, resetNoData, token, view }) => {
  const initialSelfState = { isOpen: false };
  const [exploreRejected, setExploreRejected] = useState(false);
  const [selfState, setSelfState] = useState(initialSelfState);
  const { isOpen } = selfState;
  const toggleResults = () => setSelfState({ ...selfState, isOpen: !isOpen });

  if (noData === true && !exploreRejected) {
    resetNoData();
    if (
      window.confirm(
        "There are no VegifyIt results yet here, would you like to explore our supported areas?"
      )
    ) {
      return (
        <Redirect
          to={{
            pathname: "/explore",
          }}
        />
      );
    } else {
      setExploreRejected(true);
    }
  }
  return (
    <div
      className={`result-sidebody-holder ${
        view === LIST_VIEW ? "open" : "closed"
      }`}
    >
      <div
        className={`result-sidebody
              ${view === LIST_VIEW ? "open" : "closed"}`}
      >
        {shouldShow.length > 0 && (
          <Fragment>
            <div className="result result-header">Restaurants:</div>
            <Restaurant isOpen={true} />
          </Fragment>
        )}
        {token ? (
          <div>
            Don't see your favorite restaurant or restaurant dish? Please{" "}
            <a href="/create/v2/search">add it here!</a>
          </div>
        ) : (
          <div>
            Don't see your favorite restaurant or restaurant dish? Please{" "}
            <a href="/signup">make an account</a> and add it!
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shouldShow: state.restaurant.restaurants,
  noData: state.restaurant.noData,
  token: state.auth.token,
  view: state.results.view,
});
export default connect(mapStateToProps, { resetNoData })(ResultSideBody);
