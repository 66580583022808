import {
  DESELECT_CUISINE,
  SELECT_CUISINE,
  UPDATE_CUISINES,
} from "../actions/types";

const initialState = {};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SELECT_CUISINE:
      return { ...state, [payload.cuisineName]: payload.cuisineValue };
    case DESELECT_CUISINE: {
      const { [payload.cuisineName]: removed, ...rest } = state;
      return rest;
    }
    case UPDATE_CUISINES:
      return { ...payload };
    default:
      return state;
  }
}
