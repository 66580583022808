import getDistance from "geolib/es/getDistance";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import RestaurantResult from "./RestaurantResult";

const Restaurant = ({
  restaurants,
  isOpen,
  selectedCuisines,
  currentLocation,
}) => {
  const filterBasedOnSelectedCuisines = (restaurant) =>
    Object.values(selectedCuisines).length === 0 ||
    restaurant.cuisine.filter((cuisine) =>
      Object.values(selectedCuisines).includes(cuisine)
    ).length > 0;

  return (
    <Fragment>
      {restaurants !== null &&
        restaurants.length > 0 &&
        restaurants
          .filter(filterBasedOnSelectedCuisines)
          .sort((a, b) => {
            if (
              getDistance(currentLocation, a.geoJson.coordinates) <
              getDistance(currentLocation, b.geoJson.coordinates)
            ) {
              return -1;
            } else if (
              getDistance(currentLocation, a.geoJson.coordinates) ===
              getDistance(currentLocation, b.geoJson.coordinates)
            ) {
              return a.name < b.name ? -1 : 1;
            } else {
              return 1;
            }
          })
          .map((restaurant) => (
            <RestaurantResult
              key={restaurant._id}
              restaurant={restaurant}
              isOpen={isOpen}
            />
          ))}
    </Fragment>
  );
};

Restaurant.propTypes = {
  restaurants: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  currentLocation: state.currentLocation,
  restaurants: state.restaurant.restaurants,
  selectedCuisines: state.selectedCuisines,
  noData: state.restaurant.noData,
});
export default connect(mapStateToProps)(Restaurant);
