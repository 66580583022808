import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const CreateSearchButton = ({ path }) => (
  <Link
    className="nav-btn"
    to={{
      pathname: "/create/v2/search",
      state: path,
    }}
  >
    <span className="btn btn-sm m-1 flex-fill">Create</span>
  </Link>
);

export default connect()(CreateSearchButton);
