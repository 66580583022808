import React from "react";
import { connect } from "react-redux";
import { StickyDiv } from "./MapResults";

const Alert = ({ alerts }) => {
  return (
    <StickyDiv>
      {" "}
      <div className="alert-container">
        {alerts.map((alert) => (
          <div
            dangerouslySetInnerHTML={{ __html: alert.message }}
            className={"alert " + alert.alertType}
          />
        ))}
      </div>
    </StickyDiv>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, {})(Alert);
