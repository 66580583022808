import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { mailingListSignup } from "../../../util/adminApi";
import {
  LeftDiv,
  SpaceApartDiv,
} from "../../layout/restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";

const SignupWidget = ({ setAlert, location, ...props }) => {
  const [userInfo, setUserInfo] = useState({});
  const [registered, setRegistered] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const { email, name, city } = userInfo;

  const register = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setAlert("Please complete recaptcha", "failure");
      return;
    }
    const resp = await mailingListSignup({
      email,
      name,
      city,
      location,
      recaptchaToken,
    });
    if (resp.response?.status === 400) {
      setAlert(
        resp.response.data.errors.map((obj) => obj.msg).join(", "),
        "failure"
      );
    } else if (resp.response?.status === 500) {
      setAlert(
        "Error when registering, please try again in a few minutes",
        "failure"
      );
    } else {
      setRegistered(true);
    }

    // setAlert(
    //   `Thank you for registering! Look for an email from harrison@vegifyit.com - it may get sent to your spam folder`,
    //   "success"
    // );
    window.scrollTo({ top: 0, behavior: "smooth" });
    setUserInfo({});
  };

  return (
    <form onSubmit={(e) => register(e)}>
      <div className="form-container">
        <LeftDiv>
          <SpaceApartDiv>
            <label className="form-label-inline">Email Address*</label>
            <input
              type="text"
              className="text-form"
              name="email"
              value={userInfo.email || ""}
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
              required
            />
          </SpaceApartDiv>
          <SpaceApartDiv>
            <label className="form-label-inline">Your Name</label>
            <input
              type="text"
              className="text-form"
              name="city"
              value={userInfo.name || ""}
              onChange={(e) =>
                setUserInfo({ ...userInfo, name: e.target.value })
              }
            />
          </SpaceApartDiv>
          <SpaceApartDiv>
            <label className="form-label-inline">City Name</label>
            <input
              type="text"
              className="text-form"
              name="city"
              value={userInfo.city || ""}
              onChange={(e) =>
                setUserInfo({ ...userInfo, city: e.target.value })
              }
            />
          </SpaceApartDiv>
          <ReCAPTCHA
            // sitekey="6Lce3vUnAAAAAGCTicLixShWy9wwSz5cxdSzRe13"
            // Prod
            sitekey="6LcH1_UnAAAAAHFTYqOMYpnwi-5255VZRD7JcsA_"
            onChange={setRecaptchaToken}
          />
          <div
            class="g-recaptcha"
            data-sitekey="6Lce3vUnAAAAAGCTicLixShWy9wwSz5cxdSzRe13"
          ></div>
          <input
            style={{
              textAlign: "center",
              alignSelf: "center",
              fontSize: "1.5em",
              height: "revert",
            }}
            className="btn-primary"
            type="submit"
            value={props.callToAction || "Pre-register"}
          />
        </LeftDiv>
        {registered && (
          <SpaceApartDiv>
            <p>
              Thank you for registering! Look for an email from
              harrison@vegifyit.com - it may get sent to your spam folder
            </p>
          </SpaceApartDiv>
        )}
      </div>
    </form>
  );
};
const mapStateToProps = (state) => ({
  location: state.currentLocation,
});
export default connect(mapStateToProps, { setAlert })(SignupWidget);
