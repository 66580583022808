import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import {
  fetchLocation,
  updateLocation,
} from "../../../actions/currentLocation";
import { getRestaurantById } from "../../../actions/restaurants";

export const toggleRestaurant = async (newStatus, restaurant, currLocation) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    lat: currLocation.lat,
    lng: currLocation.lng,
    status: newStatus,
  });
  try {
    const response = await axios.put(
      "/api/restaurants/popup/toggle/" + restaurant._id,
      body,
      config
    );
    console.log(response.data);
    setAlert("Restaurant set to " + newStatus.toLowerCase(), "success");
  } catch (err) {
    console.log(err);
    console.log(err.response);
    setAlert(
      err.response.data.errors
        ? err.response.data.errors[0].msg
        : "Error toggling restaurant, please try again",
      "failure"
    );
    console.log(err.response.data.errors);
  }
};

const PopupToggleWidget = ({
  getRestaurantById,
  restaurant,
  token,
  setAlert,
  currLocation,
  fetchLocation,
  updateLocation,
  ...props
}) => {
  const [status, setStatus] = useState(restaurant.status);
  const toggleStatus = () => {
    const newValue = status === "OFFLINE" ? "ONLINE" : "OFFLINE";
    setStatus(newValue);
    toggleRestaurant(newValue, restaurant, currLocation);
  };
  useEffect(() => {
    async function getGeos() {
      console.log(currLocation);
      if (!currLocation.lat && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            updateLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.log("error getting position");
            console.log(error);
            fetchLocation(false);
          }
        );
      } else {
      }
    }
    getGeos();
  }, [currLocation, fetchLocation, updateLocation]);
  const toggle = () => {
    toggleStatus();
  };

  console.log(restaurant);

  return (
    <input
      //   style={{ alignSelf: "center", fontSize: "2em", height: "2em" }}
      className={`btn btn-primary btn-sm m-1 flex-fill ${
        status !== "OFFLINE" && status !== "ONLINE" ? "none" : ""
      }`}
      type="button"
      onClick={toggle}
      value={`Go ${(status === "OFFLINE"
        ? "ONLINE"
        : "OFFLINE"
      ).toLowerCase()}`}
    />
  );
};

const mapStateToProps = (state, props) => ({
  token: state.auth.token,
  currLocation: state.currentLocation,
});
export default connect(mapStateToProps, {
  getRestaurantById,
  setAlert,
  fetchLocation,
  updateLocation,
})(PopupToggleWidget);
