import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 28px 25px;
`;

const Text = styled.p`
  text-align: left;
  padding: 0 10px;
  flex: 1 1 auto;
  margin: 0 0 10px;
  white-space: nowrap;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Column = styled.div`
  flex: 1 1 0;
  justify-content: flex-start;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 28px;
`;

const ResultTitleFrame = ({ textArray, detailsLink }) => {
  const input = textArray.slice(0, 6);
  if (textArray.length > 6) {
    input[5] = detailsLink;
  }

  return (
    <Container>
      {input.map((text, index) => (
        <Text key={index}>{text}</Text>
      ))}
    </Container>
  );
};

export default ResultTitleFrame;
