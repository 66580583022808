import axios from "axios";

// export const getRestaurantById = async id => {
//   const options = {
//     method: 'GET',
//     url: '/api/restaurants/' + id
//   };
//   try {
//     return (await axios(options)).data;
//   } catch (error) {
//     console.log(error.response.data);
//   }
// };

export const getRestaurantDetailsById = async (id) => {
  const options = {
    method: "GET",
    url: "/api/restaurantDetails/" + id,
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
  }
};

export const updateRestaurantDetails = async (id, restaurant, setAlert) => {
  const options = {
    method: "PUT",
    url: "/api/restaurantDetails/" + id,
    data: restaurant,
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
    error.response.data.errors &&
      setAlert(error.response.data.errors[0].msg, "failure");
  }
};
