import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { getRestaurantById } from "../../../actions/restaurants";
import CheckUnauth from "../../auth/CheckUnauth";
import RestaurantEditForm from "../restaurantBuilder/restaurantBuilderLegacy/RestaurantEditForm";
import { CenteredContainer, CenteredContent } from "./RestaurantDetails";

const RestaurantEdit = ({
  getRestaurantById,
  restaurant,
  token,
  setAlert,
  ...props
}) => {
  const { id } = props.match.params;
  if (restaurant === undefined) {
    getRestaurantById(id);
  }
  const updateRestaurant = async (restaurant) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(restaurant);
    try {
      const response = await axios.put("/api/restaurants/" + id, body, config);
      console.log(response.data);
      setAlert("Restaurant successfully updated", "success");
    } catch (err) {
      console.log(err.response);
      setAlert(
        err.response.data.errors
          ? err.response.data.errors[0].msg
          : "Error updating restaurant, please try again",
        "failure"
      );
      console.log(err.response.data.errors);
    }
  };

  console.log(restaurant);
  return (
    <CenteredContainer>
      <CheckUnauth {...props.location} />
      <CenteredContent>
        {restaurant && (
          <RestaurantEditForm
            currRestaurant={restaurant}
            submitBackendCall={updateRestaurant}
            displayText="Update Restaurant"
          />
        )}
      </CenteredContent>
    </CenteredContainer>
  );
};

const mapStateToProps = (state, props) => ({
  token: state.auth.token,
  restaurant: state.restaurant.restaurants.filter(
    (restaurant) => restaurant._id === props.match.params.id
  )[0],
});
export default connect(mapStateToProps, { getRestaurantById, setAlert })(
  RestaurantEdit
);
