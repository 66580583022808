import {
  FETCH_LOCATION,
  UPDATE_CURRENT_LOCATION,
  UPDATE_MAP_CENTER,
  UPDATE_RADIUS,
} from "./types";

export const updateLocation =
  ({ lat, lng }, dependentAction) =>
  (dispatch, getState) => {
    console.log(UPDATE_CURRENT_LOCATION);
    dispatch({
      type: UPDATE_CURRENT_LOCATION,
      payload: { lat, lng },
    });
    dispatch({
      type: UPDATE_MAP_CENTER,
      payload: { lat, lng },
    });
    dispatch({
      type: FETCH_LOCATION,
      payload: false,
    });
    if (dependentAction) {
      dispatch(dependentAction(getState().mapCenter));
    }
  };

export const fetchLocation = (boolean) => (dispatch) => {
  dispatch({
    type: FETCH_LOCATION,
    payload: boolean === undefined ? false : boolean,
  });
};

export const updateMapCenter =
  ({ lat, lng }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_MAP_CENTER,
      payload: { lat, lng },
    });
  };

export const updateRadius = (radius) => (dispatch) => {
  dispatch({
    type: UPDATE_RADIUS,
    payload: radius,
  });
};

export const parseLocationFromString = (commaSeparatedString) => {
  console.log(commaSeparatedString);
  const [lat, lng] = commaSeparatedString.split(",");
  return { lat, lng };
};
