import React from "react";
import { connect } from "react-redux";
import { logout } from "../../../actions/auth";

const LogoutButton = ({ logout }) => {
  return (
    <a className="nav-btn">
      {" "}
      <span className="btn  btn-sm m-1 flex-fill" onClick={() => logout()}>
        Logout
      </span>
    </a>
  );
};

const mapStateToProps = (state) => ({
  currentLocation: state.currentLocation,
  mapCenter: state.mapCenter,
});
export default connect(mapStateToProps, { logout: logout })(LogoutButton);
