import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  fetchLocation,
  parseLocationFromString,
  updateLocation,
  updateMapCenter,
} from "../../actions/currentLocation";
import {
  fetchResults,
  updateSelectedCuisines,
} from "../../actions/restaurants";
import { LIST_VIEW } from "../../actions/types";
import { getLatLong } from "../../util/geoUtils";
import { getRestaurantCuisines } from "../../util/getRestaurantCuisines";
import CuisinePicker from "../common/CuisinePicker";

const SearchBar = ({
  fetchResultsAction,
  currentLocation,
  mapCenter,
  updateLocation,
  updateMapCenter,
  fetchLocation,
  selectedCuisines,
  updateSelectedCuisines,
  view,
  props,
}) => {
  const initialFormData = {
    cuisines: [],
    latLngString: "",
    showCurrentLocationOption: false,
  };

  const [otherCuisineText, setOtherCuisineText] = useState("");

  const [formData, setFormData] = useState(initialFormData);
  const { cuisines, latLngString, keyword, showCurrentLocationOption } =
    formData;
  const [stringifiedCuisines, setStringifiedCuisines] = useState("");
  const [reducedState, setReducedState] = useState({
    keyword,
    stringifiedCuisines,
    lat: currentLocation.lat,
    lng: currentLocation.lng,
    mapLat: mapCenter.lat,
    mapLng: mapCenter.lng,
    radius: currentLocation.radius,
  });

  const searchTools = useRef(null);

  const [open, setOpen] = useState(false);

  const blur = (e) => {
    if (e.relatedTarget && searchTools.current.contains(e.relatedTarget)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    async function fetchCuisines() {
      const response = await getRestaurantCuisines();
      setFormData((formData) => ({ ...formData, cuisines: response }));
    }
    fetchCuisines();
  }, []);

  useEffect(() => {
    console.log(selectedCuisines);
    setStringifiedCuisines(Object.values(selectedCuisines).join(","));
  }, [selectedCuisines]);

  useEffect(() => {
    setReducedState((reducedState) => ({
      ...reducedState,
      mapLat: mapCenter.lat,
      mapLng: mapCenter.lng,
      radius: currentLocation.radius,
    }));
  }, [mapCenter.lat, mapCenter.lng, currentLocation.radius]);

  // useEffect(() => {
  //   setReducedState((reducedState) => ({
  //     ...reducedState,
  //     keyword: keyword,
  //   }));
  // }, [keyword]);

  useEffect(() => {
    if (!currentLocation.fetchLocation) {
      fetchResultsAction(
        {
          lat: reducedState.mapLat,
          lng: reducedState.mapLng,
          radius: reducedState.radius,
        },
        reducedState.selectedCuisines,
        reducedState.keyword
      );
    }
  }, [
    currentLocation.fetchLocation,
    fetchLocation,
    fetchResultsAction,
    reducedState.mapLat,
    reducedState.mapLng,
    reducedState.selectedCuisines,
    reducedState.radius,
    reducedState.keyword,
  ]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    e.preventDefault();
    setLocation(latLngString);
    setReducedState((reducedState) => ({
      ...reducedState,
      keyword,
    }));
  };
  const onFocus = () =>
    setFormData({ ...formData, showCurrentLocationOption: true });
  const onBlur = (e) => {
    e.preventDefault();
    setFormData({ ...formData, showCurrentLocationOption: false });
  };
  const setLocation = async (zipcode) => {
    if (zipcode && zipcode !== "" && zipcode.length >= 5) {
      fetchLocation();
      const { lat, lng } = (await getLatLong(zipcode)).data;
      props.history.push(`?lat=${lat}&lng=${lng}`);
      return updateLocation({ lat, lng });
    }
    props.history.push(
      `?lat=${reducedState.mapLat}&lng=${reducedState.mapLng}`
    );
    fetchResultsAction(
      {
        lat: reducedState.mapLat,
        lng: reducedState.mapLng,
        radius: reducedState.radius,
      },
      stringifiedCuisines,
      keyword
    );
  };
  const getUserLocation = () => {
    if (navigator.geolocation) {
      fetchLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          props.history.push(`?lat=${latitude}&lng=${longitude}`);
          updateLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.log("error getting position");
          console.log(error);
          if (error.message.includes("denied")) {
            alert("This requires location to be shared!");
          }
          fetchLocation(false);
        }
      );
    }
  };

  return (
    <div
      className={`search-tools-container flex-parent`}
      ref={searchTools}
      tabIndex={0}
      onFocus={() => setOpen(true)}
      onBlur={blur}
    >
      <span className={`search-tools-style ${view === LIST_VIEW && "none"}`}>
        <i class="fas fa-magnifying-glass"></i> Search
      </span>
      <form
        className="form-inline flex-parent search-tools-style absolute"
        autoComplete="off"
        style={{ display: open ? "inherit" : "none" }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div className="flex-parent flex-grow">
          <div
            className="form-group form-control"
            style={{ display: "flex", position: "relative" }}
            onFocus={() => onFocus()}
            onBlur={(e) => onBlur(e)}
          >
            <input
              type="text"
              placeholder="Location"
              name="latLngString"
              value={latLngString}
              onChange={(e) => onChange(e)}
              className={"flex-text"}
            />
            <input
              className="flex-fill inline-search-button"
              value="&#xe521;"
              type="submit"
            ></input>
            <div
              className={
                "form-option " +
                (showCurrentLocationOption ? "visible" : "hidden")
              }
              onMouseDown={() => {
                getUserLocation();
                setFormData({ ...formData, latLngString: "" });
              }}
            >
              <i className="fas fa-location-arrow" style={{ color: "white" }} />
              <span className="vertical-center" style={{ color: "white" }}>
                &nbsp;Use my location
              </span>
            </div>
          </div>
          <input
            type="text"
            placeholder="Keyword"
            name="keyword"
            value={keyword}
            onChange={(e) => onChange(e)}
            className={"flex-text"}
          />
          <CuisinePicker
            className="form-control"
            passedCuisines={cuisines}
            selectedCuisines={selectedCuisines}
            otherCuisineText={otherCuisineText}
            updateSelectedCuisines={updateSelectedCuisines}
            setOtherCuisineText={setOtherCuisineText}
          />
        </div>
      </form>
    </div>
  );
};
SearchBar.propTypes = {
  fetchResults: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  currentLocation: state.currentLocation,
  mapCenter: state.mapCenter,
  selectedCuisines: state.selectedCuisines,
  view: state.results.view,
});
export default connect(mapStateToProps, {
  fetchResultsAction: fetchResults,
  updateLocation,
  updateMapCenter,
  parseLocationFromString,
  fetchLocation,
  updateSelectedCuisines,
})(SearchBar);
