import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { getMissingGoogleIds, updateRestaurant } from "../../../util/adminApi";
import styled from "styled-components";
import { fetchSuggestions } from "../../../actions/restaurantBuilder";
import getDistance from "geolib/es/getDistance";
import math from "mathjs";
import { setAlert } from "../../../actions/alert";

export const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;
export const Panel = styled.div`
  min-width: 33%;
  display: flex;
`;

const AddGoogleIDs = ({ fetchMethod, setAlert, suggestions, ...props }) => {
  const [missingIdRestaurants, setMissingRestaurants] = useState({
    listRestaurants: [],
    index: 0,
  });

  const [searching, setSearching] = useState({
    searching: true,
  });

  const { listRestaurants, index } = missingIdRestaurants;

  useEffect(() => {
    async function getRestaurants() {
      const foundRestaurant = await getMissingGoogleIds();
      setMissingRestaurants({
        ...missingIdRestaurants,
        listRestaurants: foundRestaurant,
      });
    }
    getRestaurants();
  }, []);

  useEffect(() => {
    setSearching(true);
    if (listRestaurants && listRestaurants[index]) {
      const [lng, lat] = listRestaurants[index].geoJson.coordinates;
      async function getSuggestion() {
        fetchMethod({ name: listRestaurants[index].name.trim() }, { lat, lng });
      }
      getSuggestion();
    }
  }, [index, listRestaurants]);

  useEffect(() => {
    setSearching(false);
  }, [suggestions]);

  const getNewList = () => {
    const newList = [...listRestaurants];
    newList.splice(index, 1);
    return newList;
  };

  const distance = (a, b) => math.unit(getDistance(a, b), "m").to("mi");

  return listRestaurants && listRestaurants[index] ? (
    <PanelContainer>
      <Panel>Restaurant name: {listRestaurants[index].name}</Panel>
      <Panel>
        {searching && "Loading..."}
        {!searching && suggestions[0] && suggestions[0].name}
      </Panel>
      <Panel>
        {/* Lat, Long: {listRestaurants[index].geoJson.coordinates[1]}, {listRestaurants[index].geoJson.coordinates[0]} */}
        {searching && "Loading..."}
        {!searching &&
          suggestions[0] &&
          math.format(
            distance(
              listRestaurants[index].geoJson.coordinates,
              suggestions[0].geometry.location
            ),
            { notation: "fixed", precision: 2 }
          )}
      </Panel>
      <Panel>
        <button
          onClick={() =>
            setMissingRestaurants({ ...missingIdRestaurants, index: index - 1 })
          }
          disabled={index === 0}
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <button
          onClick={() =>
            setMissingRestaurants({ ...missingIdRestaurants, index: index + 1 })
          }
          disabled={index === listRestaurants.length - 1}
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </Panel>
      <Panel>
        <button
          onClick={async () => {
            await updateRestaurant({
              id: listRestaurants[index]._id,
              googleId: suggestions[0].place_id,
              metadata: {
                isGoogle: listRestaurants[index].isGoogle || false,
                lastModified: new Date(),
              },
            });
            setAlert("Restaurant updated!", "success", 1000);
            console.log(getNewList());
            setMissingRestaurants({
              ...missingIdRestaurants,
              listRestaurants: getNewList(),
            });
          }}
          disabled={searching}
        >
          <i class="fas fa-check"></i>
        </button>
      </Panel>
    </PanelContainer>
  ) : (
    <div />
  );
};
const mapStateToProps = (state) => ({
  suggestions: state.restaurantBuilder.results,
});
export default connect(mapStateToProps, {
  setAlert,
  fetchMethod: ({ name }, { lat, lng }) =>
    fetchSuggestions({ name })({ lat, lng }),
})(AddGoogleIDs);
