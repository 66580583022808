import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { login, resetPassword } from "../../actions/auth";

const Login = ({ token, login, setAlert, resetPassword, ...props }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  useEffect(() => {
    if (token) {
      props.history.push(props.location.state);
    }
  }, [props.history, props.location.state, token]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async (e) => {
    console.log("submitted!");
    e.preventDefault();
    await login({ email, password });
  };
  const onResetPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setAlert("Email is required before resetting password", "failure");
      return;
    }
    if (window.confirm(`Reset password for ${email}?`)) {
      console.log("ok clicked");
      resetPassword(email);
    }
  };
  return (
    <div className="container h-100" style={{ flexGrow: 1 }}>
      <Helmet>
        <title>VegifyIt | Login</title>
      </Helmet>
      <div className="row align-items-center h-100">
        <div className="col-4 mx-auto text-center">
          <div className="h3 mb-3">Login to VegifyIt</div>
          <form className="form-signin" onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                className="form-control"
              />
            </div>
            <div>
              <input type="submit" className="btn btn-primary" value="Login" />
              <button
                className="btn-primary"
                onClick={(e) => onResetPassword(e)}
              >
                Forgot password?
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps, { login, resetPassword, setAlert })(
  Login
);
