import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import SignupWidget from "./SignupWidget";

const SignupPage = ({ currentLocation, ...props }) => {
  return (
    <div className="create-container">
      <Helmet>
        <title>VegifyIt | Sign Up</title>
      </Helmet>
      <div style={{ width: "75vw" }}>
        <p>
          Thank you for your interest in VegifyIt! We're currently in a limited
          roll-out of user accounts.
        </p>
        <p>
          Please sign up for an account here, and we'll get back to you shortly!{" "}
        </p>

        <SignupWidget />
      </div>
    </div>
  );
};
export default connect(() => {}, {})(SignupPage);
