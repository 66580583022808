import MDEditor from "@uiw/react-md-editor";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { setAlert } from "../../../actions/alert";
import { getRestaurantById } from "../../../actions/restaurants";
import {
  getRestaurantDetailsById,
  updateRestaurantDetails,
} from "../../../util/getRestaurantInfo";
import CheckUnauth from "../../auth/CheckUnauth";
import {
  LeftDiv,
  SpaceApartDiv,
} from "../restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";
import { CenteredContainer, CenteredContent } from "./RestaurantDetails";

const RestaurantDetailsEdit = ({
  getRestaurantById,
  restaurant,
  setAlert,
  ...props
}) => {
  const [detailsData, setRestaurant] = useState({
    // restaurantDetails: {},
  });
  const [value, setValue] = useState("");
  const { id } = props.match.params;
  console.log(props);
  if (restaurant === undefined) {
    getRestaurantById(id);
  }
  useEffect(() => {
    async function getDetails() {
      const details = await getRestaurantDetailsById(id);
      setRestaurant((prevState) => ({
        ...prevState,
        restaurantDetails: details,
      }));
    }
    getDetails();
  }, [id]);
  useEffect(() => {
    if (detailsData && detailsData.restaurantDetails) {
      setValue(detailsData.restaurantDetails.menu);
    }
  }, [detailsData]);
  return (
    <CenteredContainer>
      {restaurant && detailsData.restaurantDetails && (
        <Fragment>
          <CheckUnauth {...props.location} />
          <CenteredContent>
            <LeftDiv>
              <Helmet>
                <title>VegifyIt | {`${restaurant.name}`} | Edit</title>
              </Helmet>
              <SpaceApartDiv>
                <h2>{restaurant.name}</h2>
              </SpaceApartDiv>

              <p>
                <b>Cuisines:</b>{" "}
                {restaurant.cuisine
                  .map((string) =>
                    string.replace(/\b\w/g, (l) => l.toUpperCase())
                  )
                  .join(", ")}
              </p>
            </LeftDiv>
          </CenteredContent>
          <div data-color-mode="light">
            <MDEditor value={value} onChange={setValue} height="200" />
          </div>
          <CenteredContent>
            <input
              className="btn btn-primary btn-sm m-1 flex-fill"
              type="button"
              value="Save"
              onClick={async () => {
                const updatedDetails = await updateRestaurantDetails(
                  detailsData.restaurantDetails.restaurantKey,
                  { ...detailsData.restaurantDetails, menu: value },
                  setAlert
                );
                if (updatedDetails) {
                  setRestaurant({
                    ...detailsData,
                    restaurantDetails: updatedDetails,
                  });
                }
              }}
            />
            {restaurant && (
              <Link
                className="btn btn-primary btn-sm m-1 flex-fill"
                to={{
                  pathname: "/details/" + restaurant._id,
                }}
              >
                Back
              </Link>
            )}
          </CenteredContent>
        </Fragment>
      )}
    </CenteredContainer>
  );
};

const mapStateToProps = (state, props) => ({
  restaurant: state.restaurant.restaurants.filter(
    (restaurant) => restaurant._id === props.match.params.id
  )[0],
});
export default connect(mapStateToProps, { setAlert, getRestaurantById })(
  RestaurantDetailsEdit
);
