import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { setAlert } from "../../../../actions/alert";
import {
  clearSuggestions,
  updateRestaurant,
} from "../../../../actions/restaurantBuilder";
import RestaurantEditForm from "./RestaurantEditForm";

export const SpaceApartDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LeftDiv = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const RestaurantCreateBuild = ({
  currRestaurant,
  setAlert,
  updateRestaurant,
  clearSuggestions,
}) => {
  const createRestaurant = async (restaurant) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(restaurant);
    try {
      const response = await axios.post("/api/restaurants", body, config);
      console.log(response.data);
      setAlert("Restaurant successfully created", "success");
    } catch (err) {
      console.log(err.response);
      setAlert(
        err.response.data.errors
          ? err.response.data.errors[0].msg
          : "Error creating restaurant, please try again",
        "failure"
      );
      console.log(err.response.data.errors);
    }
    clearSuggestions();
    updateRestaurant(null);
  };
  if (currRestaurant === null) {
    return <Redirect to="/create/search" />;
  }
  return (
    <div className="row justify-content-right">
      <div className="col m-3 text-right">
        <div className="h3">Create a restaurant</div>
        <RestaurantEditForm
          currRestaurant={currRestaurant}
          submitBackendCall={createRestaurant}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currRestaurant: state.restaurantBuilder.restaurant,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  setAlert,
  updateRestaurant,
  clearSuggestions,
})(RestaurantCreateBuild);
