import axios from "axios";
import GoogleMapReact from "google-map-react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlert } from "../../../../actions/alert";
import { getLatLong } from "../../../../util/geoUtils";
import MapMarker from "../../MapMarker";
import { ModalContent } from "../../MapResults";
import {
  LeftDiv,
  SpaceApartDiv,
} from "../restaurantBuilderLegacy/RestaurantCreateBuild";
import LocationPickerWidget from "./shared/LocationPickerWidget";
const RestaurantNameAddressLoc = ({ setAlert, ...props }) => {
  const [formData, setFormData] = useState({
    unsetGeoJson: { coordinates: [] },
    unsetAddress: "",
    status: "RESTAURANT",
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [addressInput, setAddressInput] = useState("google");
  useEffect(() => {
    if (props.location.state) {
      const { name, address, geoJson } = props.location.state;
      setFormData((oldData) => ({ ...oldData, name, address, geoJson }));
    }
  }, [props.location.state]);
  const {
    name,
    address,
    geoJson,
    unsetGeoJson,
    searchAccepted,
    unsetAddress,
    status,
  } = formData;

  useEffect(() => {
    async function getStartingPoint() {
      if (!geoJson && navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(
          (position) => {
            setFormData((oldData) => ({
              ...oldData,
              unsetGeoJson: {
                coordinates: [
                  position.coords.longitude,
                  position.coords.latitude,
                ],
              },
            }));
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
      }
    }
    getStartingPoint();
  }, [geoJson]);

  const confirmData = (e) => {
    e.preventDefault();
    redirectToMenu(geoJson, status);
  };

  const redirectToMenu = (geoJson, status) => {
    history.push({
      pathname: "/create/v2/cuisine",
      state: { ...props.location.state, address, geoJson, status, name },
    });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const lookupAddress = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const [lng, lat] = unsetGeoJson.coordinates;
      const resp = await axios.get(
        `/api/restaurant_builder/address?lat=${lat}&lng=${lng}`
      );
      const address = resp.data;
      setFormData((old) => ({
        ...old,
        address,
        geoJson: { coordinates: [lng, lat] },
      }));
    } catch (e) {}
    setLoading(false);
  };

  const lookupLocation = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { lat, lng } = (await getLatLong(unsetAddress)).data;
      setFormData((old) => ({
        ...old,
        address: unsetAddress,
        geoJson: { coordinates: [lng, lat] },
      }));
    } catch (e) {
      setAlert(
        `${unsetAddress} not recognized as a valid address, please try a different input`,
        "failure"
      );
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="create-container">
        <form onSubmit={(e) => confirmData(e)}>
          <div className="form-container">
            <h2>Create a Restaurant</h2>
            <h3>Restaurant Name & Location</h3>
            <LeftDiv>
              <SpaceApartDiv>
                <label className="form-label-inline">Restaurant Name*</label>
                <input
                  type="text"
                  className="text-form"
                  name="name"
                  value={name}
                  onChange={onChange}
                  required
                />
              </SpaceApartDiv>
              {geoJson && (
                <Fragment>
                  <SpaceApartDiv style={{ aspectRatio: "1/1" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyCHTvWtg42OxUZ6oyN0YeM0Oyy8oCRHFxs",
                      }}
                      center={{
                        lat: geoJson.coordinates[1],
                        lng: geoJson.coordinates[0],
                      }}
                      google={undefined}
                      defaultZoom={12}
                    >
                      <MapMarker
                        restaurant={{ name, geoJson, cuisine: [] }}
                        lat={geoJson.coordinates[1]}
                        lng={geoJson.coordinates[0]}
                        text={
                          name?.length >= 16 ? name.slice(0, 14) + "..." : name
                        }
                        showLabel={false}
                      />
                    </GoogleMapReact>
                    {/* </div> */}
                  </SpaceApartDiv>
                  <SpaceApartDiv>
                    <label className="form-label-inline">Address*</label>
                    <textarea
                      className="text-form"
                      name="address"
                      value={address}
                      onChange={onChange}
                    />
                  </SpaceApartDiv>
                  <input
                    style={{
                      textAlign: "center",
                      alignSelf: "center",
                      fontSize: "1.5em",
                      height: "revert",
                    }}
                    className="btn-primary"
                    type="submit"
                    value={"Confirm & Proceed"}
                  />
                </Fragment>
              )}
              {!geoJson && !searchAccepted && (
                <SpaceApartDiv style={{ position: "relative" }}>
                  <div
                    style={{
                      aspectRatio: "1/1",
                    }}
                  >
                    <ModalContent
                      style={{ textAlign: "left", margin: "16px 0 0 0" }}
                    >
                      No address found for {name}.
                      <hr />
                      <div>
                        <input
                          type="radio"
                          id="google"
                          name="getAddress"
                          value="google"
                          checked={addressInput === "google"}
                          onChange={(e) => setAddressInput("google")}
                        />
                        <label htmlFor="google">
                          Let VegifyIt look it up automatically{" "}
                          <span style={{ fontSize: "15px" }}>
                            (easiest option)
                          </span>
                        </label>
                      </div>
                      <hr />
                      <div>
                        <input
                          type="radio"
                          id="address"
                          name="getAddress"
                          value="address"
                          checked={addressInput === "address"}
                          onChange={(e) => setAddressInput("address")}
                        />
                        <label htmlFor="address">Type the address</label>
                      </div>
                      <hr />
                      <div>
                        <input
                          type="radio"
                          id="map"
                          name="getAddress"
                          value="map"
                          checked={addressInput === "map"}
                          onChange={(e) => setAddressInput("map")}
                        />
                        <label htmlFor="map">
                          Locate restaurant on map{" "}
                          <span style={{ fontSize: "15px" }}>
                            (helpful if you're at the restaurant now)
                          </span>
                        </label>
                      </div>
                      <hr />
                      <div>
                        <input
                          type="radio"
                          id="popup"
                          name="getAddress"
                          value="popup"
                          checked={addressInput === "popup"}
                          onChange={(e) => setAddressInput("popup")}
                        />
                        <label htmlFor="popup">
                          No permanent address{" "}
                          <span style={{ fontSize: "15px" }}>
                            (popup/food truck)
                          </span>
                        </label>
                      </div>
                      <button
                        className="btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          switch (addressInput) {
                            case "map":
                              setFormData((data) => ({
                                ...data,
                                searchAccepted: "map",
                              }));
                              break;
                            case "address":
                              setFormData((data) => ({
                                ...data,
                                searchAccepted: "address",
                              }));
                              break;
                            case "popup":
                              if (e.target.form.reportValidity()) {
                                redirectToMenu(unsetGeoJson, "OFFLINE");
                              }
                              break;
                            case "google":
                              if (e.target.form.reportValidity()) {
                                history.push({
                                  pathname: "/create/v2/address/google",
                                  state: {
                                    ...props.location.state,
                                    name,
                                  },
                                });
                              }
                              break;
                            default:
                          }
                        }}
                      >
                        Go
                      </button>
                    </ModalContent>
                  </div>
                </SpaceApartDiv>
              )}
              {!geoJson && searchAccepted === "address" && (
                <Fragment>
                  <SpaceApartDiv>
                    <label className="form-label-inline">
                      Restaurant Address*
                    </label>
                    <textarea
                      value={unsetAddress}
                      onChange={(e) => {
                        const textValue = e.target.value;
                        setFormData((data) => ({
                          ...data,
                          unsetAddress: textValue,
                        }));
                      }}
                    ></textarea>
                    <input
                      style={{
                        textAlign: "center",
                        alignSelf: "center",
                        fontSize: "1.5em",
                        height: "revert",
                      }}
                      disabled={loading}
                      onClick={lookupLocation}
                      className="btn-primary"
                      type="button"
                      value={"Submit"}
                    />
                  </SpaceApartDiv>
                </Fragment>
              )}

              {!geoJson && searchAccepted === "map" && (
                <LocationPickerWidget
                  unsetGeoJson={unsetGeoJson}
                  setFormData={setFormData}
                  isDisabled={loading}
                  name={name}
                  submitCallback={lookupAddress}
                />
              )}
            </LeftDiv>
          </div>
        </form>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  currRestaurant: state.restaurantBuilder.restaurant,
  token: state.auth.token,
});
export default connect(mapStateToProps, { setAlert })(RestaurantNameAddressLoc);
