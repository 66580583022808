import React from "react";
import styled from "styled-components";
import { SpaceApartDiv } from "../../restaurantBuilderLegacy/RestaurantCreateBuild";

const Deselect = styled.span`
  color: red;
  border: 2px solid darkgray;
  padding: 1px 1px 0px;
  margin: 1px;
  ${(props) => {
    if (props.selected) {
      return `
        text-decoration: line-through
        `;
    }
  }}
`;

const MenuItems = ({
  options,
  selectedVeganOptions,
  deselectedVeganOptions,
  selectOption,
  deselectOption,
}) => {
  return (
    <div>
      {options?.map((option) => {
        const selected = selectedVeganOptions.hasOwnProperty(option.option);
        return (
          <SpaceApartDiv>
            <div
              className={`result-body`}
              style={
                deselectedVeganOptions.hasOwnProperty(option.option)
                  ? {
                      textDecoration: "line-through",
                      textDecorationThickness: "2px",
                    }
                  : {}
              }
            >
              <label>
                <input
                  name={option.option}
                  value={option.description}
                  type="checkbox"
                  onChange={selectOption}
                  checked={selected}
                />
                {option.option} {option.description}
              </label>
              <Deselect
                selected={deselectedVeganOptions.hasOwnProperty(option.option)}
                onClick={(e) =>
                  deselectOption(e, option.option, option.description)
                }
              >
                <i class="fa-solid fa-x"></i>
              </Deselect>

              <hr />
            </div>
          </SpaceApartDiv>
        );
      })}
    </div>
  );
};

export default MenuItems;
