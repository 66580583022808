import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  UNAUTHENTICATE,
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      console.log(state);
      return { ...state, token: payload.token };
    case UNAUTHENTICATE:
      return { ...state, token: null };
    default:
      return state;
  }
}
