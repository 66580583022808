import axios from "axios";
import { setAlert } from "./alert";
import { LOGIN_SUCCESS, REGISTER_SUCCESS, UNAUTHENTICATE } from "./types";

export const register =
  ({ email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email, password });
    try {
      const res = await axios.post("/api/users/register", body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const registerWithSecretCode =
  ({ email, password, secretCode }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email, password, secretCode });
    try {
      const res = await axios.post(
        "/api/users/registerSecretCode",
        body,
        config
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      error.response.data.errors &&
        setAlert(error.response.data.errors[0].msg, "failure")(dispatch);
    }
  };

export const resetPassword = (email) => async (dispatch) => {
  if (!email) {
    setAlert("Email is required!", "failure")(dispatch);
  }
  try {
    await axios.put(`/api/users/reset-password/${email}`);
    setAlert(
      `A reset password email will be sent to ${email}`,
      "success"
    )(dispatch);
  } catch (error) {
    console.log(error);
  }
};

export const saveNewPassword =
  ({ email, password, secretCode }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email, password, secretCode });
    try {
      const res = await axios.post("/api/users/reset-password", body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      error.response.data.errors &&
        setAlert(error.response.data.errors[0].msg, "failure")(dispatch);
    }
  };

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email, password });
    try {
      const res = await axios.post("/api/users/login", body, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error.response.data.errors);
      error.response.data.errors &&
        setAlert(error.response.data.errors[0].msg, "failure")(dispatch);
    }
  };

export const logout = () => async (dispatch) =>
  dispatch({ type: UNAUTHENTICATE });
