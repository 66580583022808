import {
  FETCH_LOCATION,
  UPDATE_CURRENT_LOCATION,
  UPDATE_RADIUS,
} from "../actions/types";

const initialState = { fetchLocation: true, radius: 10 };

// const getInitialState = () => {
//   if (navigator.geolocation) {
//     const funct = navigator.geolocation.getCurrentPosition(position => {
//       const { latitude, longitude } = position.coords;
//       return { lat: latitude, lng: longitude };
//     });
//     return funct();
//   } else {
//     return { lat: 33.488677, lng: -112.345279 };

//   }
// }
// const initialState = getInitialState();

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CURRENT_LOCATION:
      return { ...state, ...payload };
    case FETCH_LOCATION:
      return { ...state, fetchLocation: payload };
    case UPDATE_RADIUS:
      return { ...state, radius: payload };
    default:
      return state;
  }
}
