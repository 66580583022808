import axios from "axios";
import {
  SUGGESTIONS_RECEIVED,
  SUGGESTIONS_CLEARED,
  UPDATE_RESTAURANT,
  UNAUTHENTICATE,
} from "./types";
import { setAlert } from "./alert";

export const fetchSuggestions =
  ({ name }) =>
  (location) =>
  async (dispatch) => {
    const options = {
      method: "GET",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      params: { input: `${name}`, location: `${location.lat},${location.lng}` },
      url: "/api/restaurants/suggestions",
    };
    try {
      const res = await axios(options);
      dispatch({ type: SUGGESTIONS_RECEIVED, payload: res.data });
    } catch (error) {
      console.log(error);
      // console.log(error.response.data.msg);
      if (error.response.status === 401) {
        dispatch(setAlert("Please reauthenticate", "failure"));
        dispatch({ type: UNAUTHENTICATE });
      }
    }
  };

export const clearSuggestions = () => async (dispatch) => {
  dispatch({ type: SUGGESTIONS_CLEARED });
};

export const updateRestaurant = (restaurant) => async (dispatch) => {
  console.log("hello world from update restaurant");
  dispatch({ type: UPDATE_RESTAURANT, payload: restaurant });
};
