import MDEditor from "@uiw/react-md-editor";
import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getRestaurantById } from "../../../actions/restaurants";
import checkAuthz from "../../../util/authz";
import { getRestaurantDetailsById } from "../../../util/getRestaurantInfo";
import {
  LeftDiv,
  SpaceApartDiv,
} from "../restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";

const RestaurantDetails = ({
  getRestaurantById,
  restaurant,
  token,
  ...props
}) => {
  const [detailsData, setRestaurant] = useState({});
  const { id } = props.match.params;
  console.log(props);
  if (restaurant === undefined) {
    getRestaurantById(id);
  }

  useEffect(() => {
    async function getDetails() {
      const details = await getRestaurantDetailsById(id);
      if (details) {
        setRestaurant((prevState) => ({
          ...prevState,
          restaurantDetails: details,
        }));
      } else {
        setRestaurant((prevState) => ({
          ...prevState,
          restaurantMenuFallback: restaurant,
        }));
      }
    }
    getDetails();
  }, [id, restaurant]);
  console.log(restaurant);

  const joinWithCommaAnd = (array) => {
    if (array.length === 0) {
      return "";
    } else if (array.length === 1) {
      return array[0];
    } else {
      let lastItem = array.pop();
      return `${array.join(", ")} and ${lastItem}`;
    }
  };

  return (
    <CenteredContainer>
      <CenteredContent>
        {restaurant && (
          <LeftDiv>
            <Helmet>
              <title>VegifyIt | {`${restaurant.name}`}</title>
              <link
                rel="canonical"
                href={`https://www.vegifyit.com/details/${id}`}
              />
              <meta
                name="description"
                content={`${
                  restaurant.name
                } offers a delicious range of vegan dishes, seamlessly integrating plant-based options into 
                ${joinWithCommaAnd(
                  restaurant.cuisine.map((string) =>
                    string.replace(/\b\w/g, (l) => l.toUpperCase())
                  )
                )} cuisine. Explore their menu for vegan-friendly delights that promise both taste and health benefits. Perfect for vegans and food enthusiasts alike!`}
              ></meta>
            </Helmet>
            <SpaceApartDiv>
              <h2>{restaurant.name}</h2>
              <p>
                {" "}
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${
                    restaurant.geoJson.coordinates[1]
                  },${restaurant.geoJson.coordinates[0]}${
                    restaurant.googleId
                      ? `&destination_place_id=${restaurant.googleId}`
                      : ``
                  }`}
                >
                  Directions
                </a>
              </p>
            </SpaceApartDiv>

            <p>
              <b>Cuisines:</b>{" "}
              {restaurant.cuisine
                .map((string) =>
                  string.replace(/\b\w/g, (l) => l.toUpperCase())
                )
                .join(", ")}
            </p>
          </LeftDiv>
        )}
        {detailsData.restaurantDetails && (
          <div data-color-mode="light">
            <MDEditor.Markdown
              style={{ padding: "1em 0em" }}
              source={detailsData.restaurantDetails.menu}
            />
          </div>
        )}
        {detailsData.restaurantMenuFallback &&
          !detailsData.restaurantDetails && <h4>Menu</h4>}
        {detailsData.restaurantMenuFallback &&
          !detailsData.restaurantDetails &&
          detailsData.restaurantMenuFallback.veganOptions.map((option) => (
            <Fragment>
              <div key={option.index}>
                &nbsp;&nbsp;&nbsp;&nbsp;{option.option}: {option.description}
                <hr />
              </div>
            </Fragment>
          ))}
        {restaurant &&
          detailsData.restaurantDetails &&
          checkAuthz(token, ["admin", restaurant._id]) && (
            <Link
              className="btn btn-primary btn-sm m-1 flex-fill"
              to={{
                pathname: "/details/" + restaurant._id + "/edit",
              }}
            >
              Edit Description
            </Link>
          )}
        {restaurant && checkAuthz(token, ["admin", restaurant._id]) && (
          <Link
            className="btn btn-primary btn-sm m-1 flex-fill"
            to={{
              pathname: "/" + restaurant._id + "/edit",
            }}
          >
            Edit Listing
          </Link>
        )}
      </CenteredContent>
    </CenteredContainer>
  );
};

const mapStateToProps = (state, props) => ({
  token: state.auth.token,
  restaurant: state.restaurant.restaurants.filter(
    (restaurant) => restaurant._id === props.match.params.id
  )[0],
});
export default connect(mapStateToProps, { getRestaurantById })(
  RestaurantDetails
);
export const CenteredContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
`;
export const CenteredContent = styled.div`
  @media only screen and (max-width: 599px) {
    width: 90%;
  }
  width: 50%;
  position: relative;
`;
