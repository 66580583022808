export const SEARCH = "SEARCH";
export const RESULTS_RECEIVED = "RESULTS_RECEIVED";
export const NO_DATA = "NO_DATA";
export const SUGGESTIONS_RECEIVED = "SUGGESTIONS_RECEIVED";
export const SUGGESTIONS_CLEARED = "SUGGESTIONS_CLEARED";
export const RESTAURANT_HOVER = "RESTAURANT_HOVER";
export const RESTAURANT_UNHOVER = "RESTAURANT_UNHOVER";
export const RESTAURANT_SELECT = "RESTAURANT_SELECT";
export const RESTAURANT_UNSELECT = "RESTAURANT_UNSELECT";
export const RESULTS_UPDATE = "RESULTS_UPDATE";
export const RESTAURANT_DELETE = "RESTAURANT_DELETE";
export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";
export const SELECT_CUISINE = "SELECT_CUISINE";
export const DESELECT_CUISINE = "DESELECT_CUISINE";
export const UPDATE_CUISINES = "UPDATE_CUISINES";

// AUTH
export const LOGIN_SUCCESS = "LOGIN_SUCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const UNAUTHENTICATE = "UNAUTHENTICATE";

// LOCATION
export const UPDATE_CURRENT_LOCATION = "UPDATE_CURRENT_LOCATION";
export const UPDATE_MAP_CENTER = "UPDATE_MAP_CENTER";
export const FETCH_LOCATION = "FETCH_LOCATION";
export const UPDATE_RADIUS = "UPDATE_RADIUS";

// RESULTS
export const MAP_VIEW = "MAP_VIEW";
export const LIST_VIEW = "LIST_VIEW";
