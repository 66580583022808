//https://medium.com/@schlunzk/integrating-google-maps-api-in-react-redux-part-1-6b036014f4a6
import getDistance from "geolib/es/getDistance";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  fetchLocation,
  updateLocation,
  updateMapCenter,
  updateRadius,
} from "../../actions/currentLocation";
import { fetchResults } from "../../actions/restaurants";
import { toggleList, toggleMap } from "../../actions/results";
import { LIST_VIEW, MAP_VIEW } from "../../actions/types";
import { getLatLong } from "../../util/geoUtils";
import { backendLog } from "../../util/logging";
import MapMarker from "./MapMarker";
export const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const RightContent = styled.div`
  text-align: right;
`;

const ClickableIcon = styled.i`
  color: white;
  padding: 0 1em;
  border-style: solid;
  border-color: green;
  border-width: 0 0 0 2px;
`;

const ClickableIconNoBorder = styled.i`
  color: white;
  padding: 0 1em;
`;

export const GrayedOutModal = styled.div`
  background-color: gray;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgreen;
`;

export const ModalContent = styled.div`
  font-size: 1.5em;
  text-align: center;
`;

const MapResults = ({
  currLocation,
  google,
  restaurants,
  updateLocation,
  fetchResultsAction,
  updateRadius,
  updateMapCenter,
  mapCenter,
  fetchLocation,
  selectedCuisines,
  toggleList,
  toggleMap,
  view,
  props,
}) => {
  const [initialize, setInitialize] = useState(false);
  const [zoom, setZoom] = useState(12);
  const toggleResults = () => (view === MAP_VIEW ? toggleList() : toggleMap());
  // setSelfState({ ...selfState, isOpen: !isOpen });
  // const [scrolled, setScrolled] = useState(false);
  const [bounds, setBounds] = useState({ se: {}, nw: {}, sw: {}, ne: {} });
  const [showLabel, setShowLabel] = useState(false);
  const normalizePath = (path) => {
    console.log(path);
    const splitPath = path.split("/");
    const state = splitPath[2]
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substr(1));
    const city = splitPath[3]
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substr(1));
    return [city.join(" "), state.join(" ")].join(", ");
  };
  const hasLatLong = () => {
    const query = props.history.location.search;
    return query && new URLSearchParams(query).get("lat");
  };

  const hasZoom = () => {
    const query = props.history.location.search;
    return query && new URLSearchParams(query).get("zoom");
  };
  useEffect(() => {
    const locationMap = {
      1018127: "massachusetts/boston",
      1021916: "new_hampshire/nashua",
      1021903: "new_hampshire/manchester",
      1021832: "new_hampshire/concord",
    };
    if (props.history.location.search) {
      const searchUrl = new URLSearchParams(props.history.location.search);
      const loc = searchUrl.get("loc");
      if (loc && locationMap[loc]) {
        props.history.push("cities/" + locationMap[loc]);
      } else if (loc && !locationMap[loc]) {
        backendLog("Unrecognized loc: " + loc);
      }
    }
  }, [props.history.location.search, props.history]);
  useEffect(() => {
    async function getLocation() {
      const normalizedLocation = normalizePath(props.history.location.pathname);
      const { lat, lng } = (await getLatLong(normalizedLocation)).data;
      return updateLocation({ lat, lng });
    }
    console.log(props.history.location);
    if (hasZoom()) {
      const searchUrl = new URLSearchParams(props.history.location.search);
      setZoom(parseFloat(searchUrl.get("zoom")));
    }
    if (hasLatLong()) {
      const searchUrl = new URLSearchParams(props.history.location.search);
      updateLocation({
        lat: parseFloat(searchUrl.get("lat")),
        lng: parseFloat(searchUrl.get("lng")),
      });
    } else if (props.history.location.pathname !== "/") {
      console.log(props.history.location.pathname);
      getLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getGeos() {
      if (
        navigator.geolocation &&
        !hasLatLong() &&
        props.history.location.pathname === "/"
      ) {
        await navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            updateLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            console.log("error getting position");
            console.log(error);
            fetchLocation(false);
          }
        );
      } else {
      }
    }
    getGeos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   window.onscroll = () =>
  //     window.pageYOffset >= 100 ? setScrolled(true) : setScrolled(false);

  //   return () => (window.onscroll = null);
  // }, []);
  useEffect(() => {
    const isInRect = (lat, lng) => {
      return (
        lat > bounds.se.lat &&
        lat < bounds.nw.lat &&
        lng < bounds.se.lng &&
        lng > bounds.nw.lng
      );
    };
    setShowLabel(
      restaurants.filter((rest) =>
        isInRect(rest.geoJson.coordinates[1], rest.geoJson.coordinates[0])
      ).length <= 5
    );
  }, [bounds, restaurants, updateRadius]);
  const handleChange = (input) => {
    const { center, bounds } = input;
    setBounds(bounds);
    if (initialize && mapCenter.lat && !currLocation.fetchLocation && center) {
      const distance = getDistance(center, bounds.nw) / 1000;
      updateRadius(distance * 2);
      updateMapCenter(center);
    }
    setInitialize(true);
  };

  const filterBasedOnSelectedCuisines = (restaurant) =>
    Object.values(selectedCuisines).length === 0 ||
    restaurant.cuisine.filter((cuisine) =>
      Object.values(selectedCuisines).includes(cuisine)
    ).length > 0;
  return (
    <StickyDiv>
      {props.history.location.pathname !== "/" && (
        <Helmet>
          <title>
            VegifyIt | Vegan Food Near{" "}
            {normalizePath(props.history.location.pathname)}
          </title>
          <meta
            name="description"
            content={`Discover vegan-friendly dishes at vegan and non-vegan restaurants in ${normalizePath(
              props.history.location.pathname
            )}! It can be hard to find vegan-specific restaurants, so we search across all restaurants for vegan options.`}
          />
        </Helmet>
      )}
      {props.history.location.pathname === "/" && (
        <Helmet>
          <title>
            Find Vegan Options Near You | VegifyIt - Discover Plant-Based Dining
          </title>
          <meta
            name="description"
            content="VegifyIt helps you find vegan options at restaurants, food trucks, and vendors near you with an interactive map. Explore plant-based dining effortlessly, whether you're looking for vegan restaurants or vegan choices at non-vegan establishments."
          />
          <meta
            name="keywords"
            content="vegan dining, vegan restaurants, find vegan food, plant-based options, vegan food map, VegifyIt, vegan food discovery, food trucks, vegan vendors"
          />
          <link rel="canonical" href="https://www.vegifyit.com" />
          <meta
            property="og:title"
            content="Find Vegan Options Near You | VegifyIt"
          />
          <meta
            property="og:description"
            content="Explore vegan dining options at restaurants, food trucks, and vendors near you with our interactive map. Discover plant-based meals effortlessly with VegifyIt."
          />
          <meta property="og:url" content="https://www.vegifyit.com" />
          <meta property="og:type" content="website" />
          {/* Additional Open Graph tags as necessary */}
          {/* Additional SEO relevant tags here */}
        </Helmet>
      )}
      <div className={`map-holder ${view === LIST_VIEW && "none"}`}>
        {!mapCenter.lat && (
          <GrayedOutModal>
            <ModalContent>
              To view results on the map, enable location services or do a
              search
            </ModalContent>
          </GrayedOutModal>
        )}
        {mapCenter.lat && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCHTvWtg42OxUZ6oyN0YeM0Oyy8oCRHFxs",
            }}
            center={mapCenter}
            // google={google}
            defaultZoom={zoom}
            onChange={handleChange}
            options={{ gestureHandling: "greedy" }}
          >
            {restaurants
              .filter(filterBasedOnSelectedCuisines)
              .map((restaurant, index) => {
                return (
                  <MapMarker
                    key={index}
                    restaurant={restaurant}
                    lat={restaurant.geoJson.coordinates[1]}
                    lng={restaurant.geoJson.coordinates[0]}
                    text={restaurant.name}
                    showLabel={showLabel}
                  />
                );
              })}
          </GoogleMapReact>
        )}
      </div>
      <RightContent
        className={`mobile-only ${view === LIST_VIEW ? "open" : "closed"}`}
      >
        {view === LIST_VIEW ? (
          <ClickableIconNoBorder
            onClick={() => toggleResults()}
            className="fas fa-map-marked-alt fa-2x"
          ></ClickableIconNoBorder>
        ) : (
          <ClickableIconNoBorder
            onClick={() => toggleResults()}
            className="fas fa-list fa-2x"
          ></ClickableIconNoBorder>
        )}
        {/* {scrolled && (
          <Fragment>
            <ClickableIcon
              onClick={() => window.scrollTo(0, 0)}
              className="fas fa-angle-double-up fa-2x"
            ></ClickableIcon>
          </Fragment>
        )} */}
      </RightContent>
    </StickyDiv>
  );
};
const mapStateToProps = (state) => ({
  currLocation: state.currentLocation,
  restaurants: state.restaurant.restaurants,
  selected: state.restaurant.selected,
  mapCenter: state.mapCenter,
  selectedCuisines: state.selectedCuisines,
  view: state.results.view,
});
export default connect(mapStateToProps, {
  updateMapCenter,
  updateLocation,
  fetchResultsAction: fetchResults,
  updateRadius,
  fetchLocation,
  toggleMap,
  toggleList,
})(MapResults);
