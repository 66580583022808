import React, { Fragment } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

export const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //   align-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const CenterDiv = styled.div`
  @media only screen and (max-width: 599px) {
    width: 75vw;
    max-width: 90%;
  }
  @media only screen and (min-width: 600px) {
    width: 50vw;
  }
`;

const Benefits = styled.section`
  margin-bottom: 30px;
`;

export const ABOUT_PAGE = (
  <Fragment>
    <Helmet>
      <title>VegifyIt | About</title>
    </Helmet>
    <div style={{ flexGrow: 1 }}>
      <h2>About VegifyIt</h2>
      <p>
        I created VegifyIt because I was a vegan living in the suburbs and I
        never could find a place to eat! Google is pretty decent at finding
        restaurants that are purely vegan, but believe it or not, there aren't
        many purely vegan restaurants in suburban Arizona!
      </p>
      <p>
        I realized that what we need is a way to discover restaurants that have
        vegan options but aren't necessarily vegan restaurants - that way we can
        still go out to eat with our friends and families - as well as
        experience new restaurants.
      </p>
      <p>
        I tried to design VegifyIt to be very easy to find and add restaurants.
        My intent is when you're in a new location or simply want to find a new
        place to eat, VegifyIt will be able to give you some suggestions. Please
        explore around, and if you are interested, please sign up for an account
        and add your favorite vegan meals and restaurants!
      </p>
    </div>
  </Fragment>
);

export const CONSULTING_PAGE = (
  <Fragment>
    <Helmet>
      <title>VegifyIt | Consulting</title>
    </Helmet>
    <div style={{ flexGrow: 1 }}>
      <h2>We Can Help You "Vegify" Your Menu</h2>
      <p>
        If you're a restauranteur who wants to be featured on VegifyIt but you
        don't have any vegan options on your menu, don't worry! We can help you
        design vegan options that match your cuisine and style.
      </p>
      <p>
        If you're interested in more information or to schedule a free initial
        consultation, please email us at{" "}
        <a href="mailto:harrison@vegifyit.com?subject=Interest in VegifyIt Consulting">
          harrison@vegifyit.com
        </a>{" "}
        or call/text 518-961-5215
      </p>
    </div>
  </Fragment>
);

export const CONTACT_PAGE = (
  <Fragment>
    <Helmet>
      <title>VegifyIt | Contact Us</title>
    </Helmet>
    <div style={{ flexGrow: 1 }}>
      <h2>Contact</h2>
      <p>
        Hi, I'm Harrison, the creator, developer, and vegan behind VegifyIt. If
        you want to get in contact with me for any reason - ideas, criticism,
        questions - please email me at harrison@vegifyit.com!
      </p>
    </div>
  </Fragment>
);

const SimplePage = ({ content }) => {
  return (
    <FlexContainer>
      <CenterDiv>{content}</CenterDiv>
    </FlexContainer>
  );
};

export default SimplePage;
