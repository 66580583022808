import React from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { saveNewPassword } from "../../../actions/auth";
import { LeftDiv } from "../../layout/restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";
import PasswordRenameWidget from "./shared/PasswordRenameWidget";

const PasswordReset = ({
  currentLocation,
  saveNewPassword,
  setAlert,
  token,
  ...props
}) => {
  if (token) {
    return (
      <div className="create-container">
        <div className="form-container">
          <LeftDiv>
            <p>Your password has been reset and you are logged in!</p>

            <br />
            <p>
              You can now add your favorite restaurant{" "}
              <a href="/create/v2/search">here</a>
            </p>
          </LeftDiv>
        </div>
      </div>
    );
  }

  return (
    <PasswordRenameWidget
      buttonText={"Reset Password"}
      passwordSaveMethod={saveNewPassword}
      {...props}
    />
  );
};
const mapStateToProps = (state, props) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, { setAlert, saveNewPassword })(
  PasswordReset
);
