import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import checkAuthz from "../../../util/authz";
import CheckUnauth from "../../auth/CheckUnauth";
import AddGoogleIDs from "./AddGoogleIDs";
import ManagePopups from "./ManagePopups";
import SendAccountInvitations from "./SendAccountInvitations";

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: left;
  // align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  // z-index: 200;
  flex-grow: 1;
  position: relative;
`;

const Tab = styled.div`
  border-radius: 10% 10% 0 0;
  padding: 0em 1em;
  min-width: 6em;
  min-height: 2em;
  display: ${(props) =>
    !checkAuthz(props.token, props.groups) ? `none` : ` flex`};
  border: 3px solid darkgreen;
  ${(props) => (props.selected === props.index ? `border-bottom: none` : ``)}
`;

const TabBody = styled.div`
  display: flex;

  width: 100%;
  ${(props) => {
    if (!checkAuthz(props.token, props.groups)) {
      return `
        display: none;
      `;
    }
  }}
`;

const AdminMainPage = ({ token, ...props }) => {
  const hash = props.location.hash;
  const [selected, setSelected] = useState(hash ? parseInt(hash[1]) : 3);

  const renderSwitch = () => {
    switch (selected) {
      case 1:
        return (
          <TabBody token={token} groups={["admin"]}>
            <AddGoogleIDs />
          </TabBody>
        );
      case 2:
        return (
          <TabBody token={token} groups={["admin"]}>
            <div>
              <SendAccountInvitations />
            </div>
          </TabBody>
        );
      default:
        return (
          <TabBody token={token} groups={[]}>
            <ManagePopups token={token} />
          </TabBody>
        );
    }
  };
  return (
    <Fragment>
      {" "}
      <CheckUnauth {...props.location} />
      <FlexContainer>
        {/*// TODO eventually this should loop through some datastructure */}
        <Tab
          index={1}
          selected={selected}
          token={token}
          groups={["admin"]}
          onClick={() => setSelected(1)}
        >
          <div>Add Google IDs</div>
        </Tab>
        <Tab
          index={2}
          selected={selected}
          token={token}
          groups={["admin"]}
          onClick={() => setSelected(2)}
        >
          <div>Send Account Invitations</div>
        </Tab>
        <Tab
          index={3}
          selected={selected}
          token={token}
          groups={[]}
          onClick={() => setSelected(3)}
        >
          <div>Manage My Restaurants</div>
        </Tab>
        {renderSwitch()}
      </FlexContainer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, {})(AdminMainPage);
