import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";
import MapResults from "./components/layout/MapResults";
import SearchBar from "./components/layout/SearchBar";
import greenv from "./components/layout/Vegifyit-Logo-RGB-LR.png";
import AdminMainPage from "./components/layout/administration/AdminMainPage";
import CreateAccount from "./components/layout/administration/CreateAccount";
import PasswordReset from "./components/layout/administration/PasswordReset";
import SignupPage from "./components/layout/administration/SignupPage";
import SimplePage, {
  ABOUT_PAGE,
  CONTACT_PAGE,
} from "./components/layout/administration/SimplePage";
import ConsultingSection from "./components/layout/copy/Consulting";
import EventsPage from "./components/layout/copy/EventsPage";
import Explore from "./components/layout/explore/Explore";
import Actions from "./components/layout/navBar/Actions";
import Footer from "./components/layout/navBar/Footer";
import RestaurantCuisine from "./components/layout/restaurantBuilder/restaurantBuilder2/RestaurantCuisine";
import RestaurantMenuManual from "./components/layout/restaurantBuilder/restaurantBuilder2/RestaurantMenuManual";
import RestaurantNameAddressLoc from "./components/layout/restaurantBuilder/restaurantBuilder2/RestaurantNameAddressLoc";
import RestaurantWebsiteFinder from "./components/layout/restaurantBuilder/restaurantBuilder2/RestaurantWebsiteFinder";
import RestaurantCreateBuild from "./components/layout/restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";
import RestaurantCreateMatch from "./components/layout/restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateMatch";
import RestaurantCreateSearch from "./components/layout/restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateSearch";
import RestaurantDetails from "./components/layout/restaurantDetails/RestaurantDetails";
import RestaurantDetailsEdit from "./components/layout/restaurantDetails/RestaurantDetailsEdit";
import RestaurantEdit from "./components/layout/restaurantDetails/RestaurantEdit";
import ResultSideBody from "./components/layout/restaurantResults/ResultSideBody";
import store from "./store";
import { backendLog } from "./util/logging";

const App = (props) => {
  useEffect(() => {
    backendLog("New visitor!");
  });
  return (
    <Provider store={store}>
      <div className="App">
        <Helmet>
          <title>VegifyIt - Discover Vegan Food Nearby</title>
        </Helmet>
        <Router>
          <div className="container1">
            <div className="navbar-brand navbar">
              <Link
                to={{
                  pathname: "/",
                }}
              >
                <img
                  className="d-inline-block mr-1"
                  alt="vegify brand"
                  src={greenv}
                />
              </Link>
              <Route path="/" component={Actions} />
            </div>
          </div>
          <Alert />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignupPage} />
          <Route
            exact
            path="/about"
            component={() =>
              SimplePage({
                content: ABOUT_PAGE,
              })
            }
          />
          <Route
            exact
            path="/contact"
            component={() =>
              SimplePage({
                content: CONTACT_PAGE,
              })
            }
          />
          <Route
            exact
            path="/consulting"
            component={() => ConsultingSection({})}
          />
          <Route exact path="/events" component={() => EventsPage({})} />
          <Route
            exact
            path="/create/search"
            component={RestaurantCreateSearch}
          />
          <Route exact path="/create/match" component={RestaurantCreateMatch} />
          <Route exact path="/create/build" component={RestaurantCreateBuild} />
          <Route
            exact
            path="/create/v2/search"
            component={RestaurantWebsiteFinder}
          />
          <Route
            exact
            path="/create/v2/address"
            component={RestaurantNameAddressLoc}
          />
          <Route
            exact
            path="/create/v2/cuisine"
            component={RestaurantCuisine}
          />
          <Route
            exact
            path="/create/v2/cuisine/manual"
            component={RestaurantMenuManual}
          />
          <Route
            exact
            path="/create/v2/address/google"
            component={RestaurantCreateSearch}
          />
          <Route exact path="/details/:id" component={RestaurantDetails} />
          <Route path="/details/:id/edit" component={RestaurantDetailsEdit} />
          <Route path="/:id/edit" component={RestaurantEdit} />
          <Route path="/admin" component={AdminMainPage} />
          <Route path="/explore" component={Explore} />
          <Route path="/create-account/:secretCode" component={CreateAccount} />
          <Route path="/reset-password/:secretCode" component={PasswordReset} />
          <Route
            exact
            path="/cities/:state/:city"
            component={(props) => (
              <div className="main-app">
                <SearchBar props={props} />
                <MapResults isMarkerShown props={props} />
                <ResultSideBody />
              </div>
            )}
          />
          <Route
            exact
            path="/"
            component={(props) => (
              <div className="main-app">
                <SearchBar props={props} />
                <MapResults isMarkerShown props={props} />
                <ResultSideBody />
              </div>
            )}
          />
          <Footer />
        </Router>
      </div>
    </Provider>
  );
};

export default App;
