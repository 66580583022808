import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { setAlert } from "../../../../actions/alert";
import {
  clearSuggestions,
  updateRestaurant,
} from "../../../../actions/restaurantBuilder";
import RestaurantEditForm from "../restaurantBuilderLegacy/RestaurantEditForm";

export const SpaceApartDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LeftDiv = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const RestaurantMenuManual = ({ setAlert, ...props }) => {
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (props.location.state) {
      console.log(props.location.state);
      const { name, address, geoJson, status } = props.location.state;
      setFormData((oldData) => ({
        ...oldData,
        name,
        address,
        geoJson,
        status,
      }));
    }
  }, [props.location.state]);
  const createRestaurant = async (restaurant) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(restaurant);
    try {
      const response = await axios.post("/api/restaurants", body, config);
      console.log(response.data);
      setAlert("Restaurant successfully created", "success");
    } catch (err) {
      console.log(err.response);
      setAlert(
        err.response.data.errors
          ? err.response.data.errors[0].msg
          : "Error creating restaurant, please try again",
        "failure"
      );
      console.log(err.response.data.errors);
    }
    clearSuggestions();
    updateRestaurant(null);
  };
  return (
    <div
      className="row justify-content-right create-container"
      style={{ justifyContent: "flex-start" }}
    >
      <div className="col m-3 text-right">
        <div className="h3">Create a restaurant</div>
        {formData.name && formData.geoJson.coordinates && (
          <RestaurantEditForm
            currRestaurant={{
              name: formData.name,
              coordinates: `${formData.geoJson.coordinates[1]},${formData.geoJson.coordinates[0]}`,
              metadata: { isGoogle: false },
              status: formData.status,
            }}
            submitBackendCall={createRestaurant}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currRestaurant: state.restaurantBuilder.restaurant,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  setAlert,
  updateRestaurant,
  clearSuggestions,
})(RestaurantMenuManual);
