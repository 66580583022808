import axios from "axios";
import { backendLog } from "../util/logging";
import {
  DESELECT_CUISINE,
  NO_DATA,
  RESTAURANT_DELETE,
  RESTAURANT_HOVER,
  RESTAURANT_SELECT,
  RESTAURANT_UNHOVER,
  RESTAURANT_UNSELECT,
  RESULTS_RECEIVED,
  SELECT_CUISINE,
  UPDATE_CUISINES,
} from "./types";

export const fetchResults =
  ({ lat, lng, radius }, cuisine, keywords) =>
  async (dispatch) => {
    if (!lat || !lng) {
      return;
    }
    try {
      const res = await axios.get(
        `/api/restaurants/query?distance=${radius}&lat=${lat}&long=${lng}${
          keywords ? `&keywords=${keywords}` : ""
        }&cuisine=${cuisine || ""}`
      );
      if (res.data.length === 0 && !cuisine) {
        dispatch({
          type: NO_DATA,
          payload: true,
        });
      }
      dispatch({
        type: RESULTS_RECEIVED,
        payload: res.data,
      });
    } catch (exception) {
      console.log(exception.response.data);
    }
  };

export const getRestaurantById = (id) => async (dispatch) => {
  const options = {
    method: "GET",
    url: "/api/restaurants/" + id,
  };
  try {
    dispatch({
      type: RESULTS_RECEIVED,
      payload: [(await axios(options)).data.restaurant],
    });
  } catch (error) {
    console.log(error.response.data);
  }
};

export const hoverRestaurant = (id) => (dispatch) => {
  dispatch({
    type: RESTAURANT_HOVER,
    payload: id,
  });
};
export const unhoverRestaurant = () => (dispatch) => {
  dispatch({
    type: RESTAURANT_UNHOVER,
  });
};
export const selectRestaurantFromMap = (id) => (dispatch) => {
  backendLog(`Restaurant selected: ${id}`);
  dispatch({
    type: RESTAURANT_SELECT,
    payload: { id, source: "MAP" },
  });
};
export const selectRestaurantFromList = (id) => (dispatch) => {
  backendLog(`Restaurant selected: ${id}`);
  dispatch({
    type: RESTAURANT_SELECT,
    payload: { id, source: "LIST" },
  });
};
export const unselectRestaurant = () => (dispatch) => {
  dispatch({
    type: RESTAURANT_UNSELECT,
  });
};

export const selectCuisine = (cuisineName, cuisineValue) => (dispatch) => {
  dispatch({
    type: SELECT_CUISINE,
    payload: { cuisineName, cuisineValue },
  });
};
export const deselectCuisine = (cuisineName) => (dispatch) => {
  dispatch({
    type: DESELECT_CUISINE,
    payload: { cuisineName },
  });
};

export const updateSelectedCuisines = (selectedCuisines) => (dispatch) => {
  dispatch({
    type: UPDATE_CUISINES,
    payload: selectedCuisines,
  });
};

export const deleteRestaurant = (id) => async (dispatch) => {
  unselectRestaurant();
  console.log("Sending delete request with id " + id);
  await axios.delete(`/api/restaurants/${id}`);
  dispatch({
    type: RESTAURANT_DELETE,
    payload: id,
  });
};

export const resetNoData = () => (dispatch) => {
  dispatch({
    type: NO_DATA,
    payload: false,
  });
};
