import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import {
  getMailingList,
  getMissingGoogleIds,
  mailingListSecretCode,
  updateRestaurant,
} from "../../../util/adminApi";
import styled from "styled-components";
import { fetchSuggestions } from "../../../actions/restaurantBuilder";
import getDistance from "geolib/es/getDistance";
import math from "mathjs";
import { setAlert } from "../../../actions/alert";
// import { Panel, PanelContainer } from './AddGoogleIDs';

export const PanelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid black;
`;
export const Panel = styled.div`
  display: flex;
  margin: 10px;
`;
const AuthorizedComponent = ({ dataFunction, renderFunction }) => {
  const [componentState, setComponentState] = useState({ data: [] });

  useEffect(() => {
    async function getData() {
      try {
        const data = await dataFunction();
        console.log(data);
        setComponentState({ ...componentState, data });
      } catch (errors) {
        console.log(errors);
        setComponentState({ ...componentState, errors });
      }
    }
    getData();
  }, [dataFunction]);

  return componentState.errors === undefined
    ? renderFunction(componentState.data)
    : `${componentState.errors.errors[0].msg}`;
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(AuthorizedComponent);
