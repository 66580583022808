import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Admin from "./Admin";
import CreateSearchButton from "./CreateSearchButton";
import ExploreButton from "./ExploreButton";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import SignUpButton from "./SignUpButton";
import SimpleButton from "./SimpleButton";

const NavActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;
const MenuIcon = styled.i`
  color: white;
  // background: darkgreen;
  // border: 5px solid darkgreen;
  padding: 3px;

  &:hover {
    color: #80aa82;
  }
`;

const DropdownMenu = styled.div`
  z-index: 300;
  background-color: #2e7d32;
  border-top: 1px solid green;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  ${(props) => {
    if (!props.openMenu) {
      return `
        display: none;
        `;
    }
  }}
`;
const FlexDiv = styled.div`
  display: flex;
`;
const Actions = ({ token, ...props }) => {
  const [openMenu, toggleMenu] = useState(false);
  const { pathname } = props.location;
  return (
    <NavActions>
      <div
        tabIndex={0}
        onBlur={(e) => {
          e.preventDefault();
          if (!e.target.contains(e.relatedTarget)) {
            toggleMenu(false);
          }
        }}
        onClick={() => toggleMenu(!openMenu)}
      >
        <MenuIcon className="fas fa-bars fa-3x"></MenuIcon>
        <DropdownMenu openMenu={openMenu}>
          <FlexDiv>
            <ExploreButton />
          </FlexDiv>
          {!token && (
            <FlexDiv>
              <SignUpButton path={pathname} />
            </FlexDiv>
          )}
          {!token && (
            <FlexDiv>
              <LoginButton path={pathname} />
            </FlexDiv>
          )}
          {token && (
            <FlexDiv>
              <Admin />
            </FlexDiv>
          )}
          {token && (
            <FlexDiv>
              <CreateSearchButton />
            </FlexDiv>
          )}
          {
            <FlexDiv>
              <SimpleButton name="consulting" />
            </FlexDiv>
          }
          {token && (
            <FlexDiv>
              <LogoutButton />
            </FlexDiv>
          )}
        </DropdownMenu>
      </div>
    </NavActions>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, {})(Actions);
