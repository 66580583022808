import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import setAuthToken from "./util/setAuthToken";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  { ...initialState, auth: { token: localStorage.getItem("token") } },
  composeWithDevTools(applyMiddleware(...middleware))
);
store.subscribe(() => {
  if (store.getState().auth.token) {
    setAuthToken(store.getState().auth.token);
    localStorage.setItem("token", store.getState().auth.token);
  } else if (!store.getState().auth.token) {
    setAuthToken();
    localStorage.removeItem("token");
  }
});
if (store.getState().auth.token) {
  setAuthToken(store.getState().auth.token);
} else if (!store.getState().auth.token) {
  setAuthToken();
}
export default store;
