import React from "react";
import { connect } from "react-redux";
import {
  selectRestaurantFromMap,
  unselectRestaurant,
} from "../../actions/restaurants";
import greenv from "./green-v-md.png";

const MapMarker = (props) => {
  const {
    restaurant,
    hover,
    selected,
    google,
    selectRestaurantFromMap,
    unselectRestaurant,
    text,
    showLabel,
  } = props;

  const lat = restaurant.geoJson.coordinates[1];
  const lng = restaurant.geoJson.coordinates[0];
  let icon = null;
  const className = `${selected || hover ? "selected" : ""}`;
  const onClick = !selected
    ? () => selectRestaurantFromMap(restaurant._id)
    : () => unselectRestaurant();

  return (
    <div>
      <img
        // {...props}
        key={restaurant._id}
        title={restaurant.name}
        name={restaurant.name}
        position={{ lat, lng }}
        icon={icon}
        onClick={onClick}
        src={greenv}
        alt={text}
        width="32"
        style={{
          position: "relative",
          left: "-16px",
          top: "-32px",
        }}
        className={className}
      />
      {(showLabel || selected) && (
        <div className="map-marker-tag">
          {text}
          <br />
          {restaurant.cuisine
            .map((string) => string.replace(/\b\w/g, (l) => l.toUpperCase()))
            .join(", ")}
        </div>
      )}
    </div>
  );
};
// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCHTvWtg42OxUZ6oyN0YeM0Oyy8oCRHFxs'
// })(MapMarker);
const mapStateToProps = (state, props) => ({
  hover:
    !state.restaurant.selected &&
    state.restaurant.hover === props.restaurant._id,
  selected: state.restaurant.selected === props.restaurant._id,
});
export default connect(mapStateToProps, {
  selectRestaurantFromMap,
  unselectRestaurant,
})(MapMarker);
