import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import {
  getMailingList,
  getMailingListFiltered,
  getMissingGoogleIds,
  mailingListSecretCode,
  updateRestaurant,
} from "../../../util/adminApi";
import styled from "styled-components";
import { fetchSuggestions } from "../../../actions/restaurantBuilder";
import getDistance from "geolib/es/getDistance";
import math from "mathjs";
import { setAlert } from "../../../actions/alert";
import AuthorizedComponent from "./AuthorizedComponent";
// import { Panel, PanelContainer } from './AddGoogleIDs';

export const PanelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 2px solid black;
`;
export const Panel = styled.div`
  display: flex;
  margin: 10px;
`;
const SendAccountInvitations = ({ setAlert }) => {
  const [filter, setFilter] = useState({ isFiltered: true });

  const renderUsers = (users) => {
    users.sort((user1, user2) => {
      if (user1.secretCode) {
        return user2.secretCode ? 0 : 1;
      } else {
        return user2.secretCode ? -1 : 0;
      }
    });
    return (
      <div>
        <label>
          <input
            name="Filter by area"
            value="Filter by area"
            type="checkbox"
            onChange={(e) => {
              console.log(e);
              setFilter({ ...filter, isFiltered: !filter.isFiltered });
            }}
            checked={filter.isFiltered}
          />
          Filter by area
        </label>

        {users.map((user) => (
          <PanelContainer>
            <Panel>User's Name: {user.name}</Panel>
            <Panel>User's email: {user.email}</Panel>
            <Panel>
              <input
                className="btn-primary"
                type="button"
                value={
                  user.secretCode ? "Resend Invitation" : "Send Invitation"
                }
                onClick={async () => {
                  try {
                    await mailingListSecretCode({ email: user.email });
                    setAlert(`Invitation sent for ${user.email} `, "success");
                  } catch (error) {
                    console.log(error.response.data);
                    setAlert(
                      `Error in creating user ${user.email}. 
                                ${error.response.data.errors[0].msg}`,
                      "failure"
                    );
                  }
                }}
              />
            </Panel>
          </PanelContainer>
        ))}
      </div>
    );
  };

  return (
    <AuthorizedComponent
      dataFunction={
        filter.isFiltered
          ? () => getMailingListFiltered({ lat: 40.97484, long: -73.93485 })
          : getMailingList
      }
      renderFunction={renderUsers}
    />
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { setAlert })(SendAccountInvitations);
