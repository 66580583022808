import { LIST_VIEW, MAP_VIEW } from "../actions/types";

const initialState = { view: MAP_VIEW };

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case MAP_VIEW:
      return { ...state, view: MAP_VIEW };
    case LIST_VIEW:
      return { ...state, view: LIST_VIEW };
    default:
      return state;
  }
}
