import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BarLoader from "react-spinners/BarLoader";
import { setAlert } from "../../../../actions/alert";
import { updateLocation } from "../../../../actions/currentLocation";
import {
  fetchSuggestions,
  updateRestaurant,
} from "../../../../actions/restaurantBuilder";
import { getLatLong } from "../../../../util/geoUtils";
import CheckUnauth from "../../../auth/CheckUnauth";
const RestaurantCreateSearch = ({
  updateRestaurant,
  suggestions,
  currRestaurant,
  updateLocation,
  setAlert,
  ...props
}) => {
  const initialFormData = {
    name: "",
    location: "",
    suggested: false,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    suggestions && setLoading(false);
  }, [suggestions]);

  const { name, location, suggested } = formData;

  useEffect(() => {
    if (props.location.state) {
      const { name } = props.location.state;
      console.log(props.location.state);
      setFormData((data) => ({ ...data, name }));
    }
  }, [props.location.state]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const suggestMatches = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLocation(location);
  };
  const setLocation = async (zipcode) => {
    console.log("hello world");
    if (zipcode && zipcode === "Use Current Location") {
      getUserLocation(fetchSuggestions(formData));
    } else if (zipcode && zipcode !== "") {
      const { lat, lng } = (await getLatLong(zipcode)).data;
      return updateLocation({ lat, lng }, fetchSuggestions(formData));
    }
  };
  const getUserLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateLocation({ lat: latitude, lng: longitude }, callback);
        },
        (error) => {
          console.log("error getting position");
          console.log(error);
          if (error.message.includes("denied")) {
            alert("This requires location to be shared!");
          } else {
            setAlert(
              "Error retrieving your location, please try a city or zipcode instead",
              "failure"
            );
          }
          setLoading(false);
        }
      );
    }
  };
  if (suggested) {
    // return <Redirect to="/create/match" />;
    if (history.location.pathname.includes("v2")) {
      console.log(formData);
      console.log(name);
      const coordinates = currRestaurant.coordinates.split(", ");
      history.push({
        pathname: "/create/v2/cuisine",
        state: {
          ...props.location.state,
          address: currRestaurant.address,
          googleId: currRestaurant.googleId,
          geoJson: {
            coordinates: [
              parseFloat(coordinates[1]),
              parseFloat(coordinates[0]),
            ],
          },
          name,
        },
      });
    } else {
      history.push({
        pathname: "/create/match",
      });
    }
  }
  return (
    // <div className="container h-100 ">
    <div className="row create-container">
      <CheckUnauth {...props.location} />
      <form className="form-container" onSubmit={(e) => suggestMatches(e)}>
        <div className="h3" style={{ fontSize: "2em" }}>
          Create a restaurant
        </div>
        <div style={{ width: "75vw" }}>
          <label className="form-label">Restaurant Name*</label>
          <div style={{ display: "flex" }}>
            <input
              style={{ width: "100%", fontSize: "2em" }}
              type="text"
              className="text-form"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
        </div>

        <div style={{ width: "75vw" }}>
          <label className="form-label">Zipcode or city*</label>
          <div style={{ display: "flex" }}>
            <input
              style={{ width: "100%", fontSize: "2em" }}
              title="Zipcode or city"
              className="text-form"
              name="location"
              value={location}
              onChange={(e) => onChange(e)}
            />
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                getUserLocation();
                setFormData({
                  ...formData,
                  location: "Use Current Location",
                });
              }}
            >
              <i class="fas fa-crosshairs" />
            </button>
          </div>
        </div>
        <input
          style={{
            alignSelf: "center",
            fontSize: "2em",
            height: "2em",
            margin: "8px",
          }}
          className="btn-primary"
          type="submit"
          value="Suggest Matches"
          disabled={loading}
        />
      </form>
      <BarLoader loading={loading} color="green" height="8px" />
      {suggestions && (
        <div className="col m-3 text-left form-container">
          {suggestions.map((suggestion) => (
            <div
              className="result"
              onClick={() => {
                console.log("hello world");
                updateRestaurant({
                  ...currRestaurant,
                  name,
                  address: suggestion.formatted_address,
                  city: suggestion.formatted_address.split(",")[1],
                  coordinates: `${suggestion.geometry.location.lat}, ${suggestion.geometry.location.lng}`,
                  googleId: suggestion.place_id,
                });
                setFormData({ ...formData, suggested: true });
              }}
              key={suggestion.place_id}
            >
              {suggestion.name}
              <br /> {suggestion.formatted_address}
              <hr />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  suggestions: state.restaurantBuilder.results,
  currRestaurant: state.restaurantBuilder.restaurant,
});
export default connect(mapStateToProps, {
  updateRestaurant,
  updateLocation,
  setAlert,
})(RestaurantCreateSearch);
