import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Admin = ({ path }) => (
  <Link
    className="nav-btn"
    to={{
      pathname: "/admin",
      state: path,
    }}
  >
    <span className="btn  btn-sm m-1 flex-fill">Admin</span>
  </Link>
);

export default connect()(Admin);
