import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const SimpleButton = ({ path, name }) => (
  <Link
    className="nav-btn"
    to={{
      pathname: `/${name}`,
      state: path,
    }}
  >
    <span className="btn btn-sm m-1 flex-fill">
      {name.charAt(0).toUpperCase() + name.slice(1)}
    </span>
  </Link>
);

export default connect()(SimpleButton);
