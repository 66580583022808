import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { registerWithSecretCode } from "../../../actions/auth";
import {
  LeftDiv,
  SpaceApartDiv,
} from "../../layout/restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";

//TODO from 95 we can reuse "PasswordRenameWidget" to simplify this class
const CreateAccount = ({
  currentLocation,
  registerWithSecretCode,
  setAlert,
  token,
  ...props
}) => {
  const [passwords, setPassword] = useState({
    password1: "",
    password2: "",
  });

  const email = new URLSearchParams(props.location.search).get("email");
  const { secretCode } = props.match.params;

  const valid = true;

  const register = async (e) => {
    e.preventDefault();
    if (passwords.password1 !== passwords.password2) {
      setAlert("Passwords must be the same!", "failure");
    } else if (passwords.password1.length < 8) {
      setAlert("Passwords must be at least 8 characters in length!", "failure");
    } else {
      registerWithSecretCode({
        email,
        secretCode,
        password: passwords.password1,
      });
    }
  };
  if (token) {
    return (
      <div className="create-container">
        <div className="form-container">
          <LeftDiv>
            <p>
              Your account has been successfully created and you are logged in!
            </p>

            <br />
            <p>
              You can now add your favorite restaurant{" "}
              <a href="/create/v2/search">here</a> or add vegan dishes to
              existing restaurants by clicking the{" "}
              <i class="fas fa-pencil-alt"></i> icon in restaurants results.
            </p>
          </LeftDiv>
        </div>
      </div>
    );
  }

  return valid ? (
    <div className="create-container">
      <form onSubmit={(e) => register(e)}>
        <div className="form-container">
          <LeftDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Email Address*</label>
              <input
                type="text"
                className="text-form"
                name="email"
                value={email}
                readOnly
              />
            </SpaceApartDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Password</label>
              <input
                type="password"
                className="text-form"
                name="password1"
                value={passwords.password1 || ""}
                onChange={(e) =>
                  setPassword({ ...passwords, password1: e.target.value })
                }
              />
            </SpaceApartDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Confirm Password</label>
              <input
                type="password"
                className="text-form"
                name="password2"
                value={passwords.password2 || ""}
                onChange={(e) =>
                  setPassword({ ...passwords, password2: e.target.value })
                }
              />
            </SpaceApartDiv>
            <input
              style={{
                textAlign: "center",
                alignSelf: "center",
                fontSize: "1.5em",
                height: "revert",
              }}
              className="btn-primary"
              type="submit"
              value={"Create Account"}
            />
          </LeftDiv>
        </div>
      </form>
    </div>
  ) : (
    <div>
      <p> Sorry that's not a valid code!</p>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps, { setAlert, registerWithSecretCode })(
  CreateAccount
);
