import {
  SUGGESTIONS_RECEIVED,
  SUGGESTIONS_CLEARED,
  UPDATE_RESTAURANT,
} from "../actions/types";

const initialState = { results: [], restaurant: null };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUGGESTIONS_RECEIVED:
      return { ...state, results: payload };
    case SUGGESTIONS_CLEARED:
      return { ...state, results: [] };
    case UPDATE_RESTAURANT:
      return { ...state, restaurant: payload };
    default:
      return state;
  }
}
