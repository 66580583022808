import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { setAlert } from "../../../actions/alert";
import { updateLocation } from "../../../actions/currentLocation";
import { updateRestaurant } from "../../../actions/restaurantBuilder";
import { deleteRestaurant } from "../../../actions/restaurants";
import CheckUnauth from "../../auth/CheckUnauth";
import LocationPickerWidget from "../restaurantBuilder/restaurantBuilder2/shared/LocationPickerWidget";
import { LeftDiv } from "../restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";
import PopupToggleWidget, {
  toggleRestaurant,
} from "../restaurantDetails/PopupToggleWidget";
import {
  CenteredContainer,
  CenteredContent,
} from "../restaurantDetails/RestaurantDetails";
import DeleteItem from "../restaurantResults/DeleteItem";

export const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
`;
export const Panel = styled.div`
  margin: 0 8px;
`;

export const MainPanel = styled.div`
  flex: 1;
`;

export const Modal = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const ManagePopups = ({ token, ...props }) => {
  const [restaurants, setRestaurants] = useState([]);
  useEffect(() => {
    async function getRestaurants() {
      setRestaurants(
        (await axios.get(`/api/restaurants/owned_restaurants`)).data
      );
    }
    getRestaurants();
  }, []);
  const [show, setShow] = useState([]);
  useEffect(() => {
    setShow(new Array(restaurants.length).fill(false));
  }, [restaurants]);

  useEffect(() => {
    function keyListener(e) {
      if (e.keyCode === 27) {
        setShow(new Array(restaurants.length).fill(false));
      }
    }

    document.addEventListener("keydown", keyListener);
    return () => document.removeEventListener("keydown", keyListener);
  }, [restaurants.length]);
  const [formData, setFormData] = useState({
    unsetGeoJson: { coordinates: [] },
  });
  const { unsetGeoJson } = formData;
  console.log(restaurants);
  return (
    <CenteredContainer style={{ width: "100%" }}>
      <CenteredContent>
        <Fragment>
          <CheckUnauth {...props.location} />
          {restaurants.map((restaurant, i) => (
            <PanelContainer>
              <Fragment>
                <MainPanel> {restaurant.name} </MainPanel>
                {restaurant.status === "ONLINE" ||
                restaurant.status === "OFFLINE" ? (
                  <Fragment>
                    <Panel>
                      <PopupToggleWidget restaurant={restaurant} />
                    </Panel>
                    <Panel>
                      <i
                        style={{ color: "#2e7d32" }}
                        className="fas fa-map-marked-alt fa-2x"
                        onClick={() => {
                          setFormData((old) => ({
                            ...old,
                            unsetGeoJson: {
                              coordinates: restaurant.geoJson.coordinates,
                            },
                          }));
                          setShow((old) => [
                            ...old.slice(0, i),
                            true,
                            ...old.slice(i + 1),
                          ]);
                        }}
                      ></i>
                      <Modal show={show[i]}>
                        {show[i] && (
                          <div
                            className="create-container"
                            style={{ margin: "54px 0 0 0 " }}
                          >
                            <div className="form-container">
                              <div
                                className="closeButton"
                                onClick={() =>
                                  setShow((old) => [
                                    ...old.slice(0, i),
                                    false,
                                    ...old.slice(i + 1),
                                  ])
                                }
                              >
                                &times;
                              </div>
                              <LeftDiv>
                                <LocationPickerWidget
                                  name={restaurant.name}
                                  unsetGeoJson={unsetGeoJson}
                                  isDisabled={false}
                                  setFormData={setFormData}
                                  submitCallback={() => {
                                    console.log("submitting");
                                    toggleRestaurant(
                                      restaurant.status,
                                      restaurant,
                                      {
                                        lat: unsetGeoJson.coordinates[1],
                                        lng: unsetGeoJson.coordinates[0],
                                      }
                                    );
                                    setShow((old) => [
                                      ...old.slice(0, i),
                                      false,
                                      ...old.slice(i + 1),
                                    ]);
                                  }}
                                  defaultZoom={18}
                                  submitButtonText="Update Location"
                                />
                              </LeftDiv>
                            </div>
                          </div>
                        )}
                      </Modal>
                    </Panel>
                  </Fragment>
                ) : null}

                <Panel>
                  <Link
                    to={{
                      pathname: "/" + restaurant._id + "/edit",
                    }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </Link>
                </Panel>
                <Panel>
                  <DeleteItem
                    id={restaurant._id}
                    deleteFunction={deleteRestaurant}
                  />
                </Panel>
              </Fragment>
            </PanelContainer>
          ))}
        </Fragment>
      </CenteredContent>
    </CenteredContainer>
  );
};

export default connect((state) => {}, {
  updateRestaurant,
  updateLocation,
  setAlert,
})(ManagePopups);
