import React from "react";
import { Helmet } from "react-helmet";

// Define the styles as a JavaScript object
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    // maxWidth: "calc(100%-40px)",
    margin: "20px auto",
    padding: "20px",
    flexGrow: 1,
  },
  header: {
    textAlign: "center",
    color: "#2E7D32",
  },
  section: {
    margin: "20px 0",
  },
  subsection: {
    marginBottom: "15px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "none",
  },
  highlight: {
    fontWeight: "bold",
  },
};

// The VegifyItComponent
const EventsPage = () => (
  <>
    {" "}
    <Helmet>
      <title>
        Vegan Food Festival Platform | VegifyIt - Elevate Your Veg Fest
        Experience
      </title>
      <meta
        name="description"
        content="Discover VegifyIt, the leading software platform for vegan food festivals. Streamline your event, connect with more customers, and showcase your vegan offerings with ease. Perfect for organizers, vendors, and attendees looking for the ultimate vegan food fest experience."
      />
      <meta
        name="keywords"
        content="vegan food festival, vegan food platform, VegifyIt, vegan festival software, food fest management, vegan vendors, vegan cuisine, plant-based festival"
      />
      <link rel="canonical" href="https://www.vegifyit.com/events" />
      {/* Add any additional SEO relevant tags here */}
    </Helmet>
    <div style={styles.container}>
      <h1 style={styles.header}>
        Elevate Your Veg Fest Experience with VegifyIt
      </h1>
      <p>
        Unlock the full potential of your Veg Fest with VegifyIt, the premier
        software platform designed to make vegan food discovery seamless for
        organizers, customers, and vendors alike.
      </p>

      <section style={styles.section}>
        <h2>Why VegifyIt?</h2>
        <div style={styles.subsection}>
          <h3 style={styles.highlight}>For Organizers:</h3>
          <p>
            Streamline event management with our comprehensive vendor directory,
            reducing your workload and ensuring attendees have instant access to
            the diverse vegan offerings at your fest. With VegifyIt, organizing
            a fest becomes a breeze, allowing you to focus on creating memorable
            experiences.
          </p>
        </div>

        <div style={styles.subsection}>
          <h3 style={styles.highlight}>For Customers:</h3>
          <p>
            Explore vegan cuisine like never before! Our platform showcases all
            vegan options, making it easy to search by cuisine or keyword. Plus,
            our dynamic map ensures you can find your favorite dishes quickly.
            Say goodbye to missed opportunities and hello to your next vegan
            adventure.
          </p>
        </div>

        <div style={styles.subsection}>
          <h3 style={styles.highlight}>For Vendors:</h3>
          <p>
            Gain visibility and connect directly with a passionate audience.
            VegifyIt offers a platform for you to showcase your offerings and
            share your story, linking directly to your social media or website.
            Expand your reach and grow your customer base with VegifyIt.
          </p>
        </div>
      </section>

      <section style={styles.section}>
        <h2>Unparalleled Features:</h2>
        <p>Our platform is built with your needs in mind, featuring:</p>
        <ul>
          <li>
            Intuitive search by cuisine or keyword, making food discovery fun
            and easy.
          </li>
          <li>
            A dynamic map view of vendors, so you're always just a few clicks
            away from finding your next meal.
          </li>
          <li>
            Easy-to-navigate vendor directories for quick access to vegan
            options.
          </li>
          <li>
            Seamless integration for vendors to connect with customers,
            including links to social media and websites.
          </li>
        </ul>
      </section>
      <p>
        Don't miss the opportunity to make your next Veg Fest a landmark event
        with VegifyIt. Whether you're an organizer, vendor, or attendee,
        VegifyIt is here to enhance your festival experience. Get started with
        VegifyIt today!{" "}
        <a
          href="mailto:harrison@vegifyit.com?subject=Interest in VegifyIt for Food Fest"
          style={styles.link}
        >
          Email us now!
        </a>
      </p>
      <section style={styles.section}>
        <h2>Watch a Video Demo:</h2>
        <iframe
          src="https://player.vimeo.com/video/920633924?h=540186ffa1"
          style={{ width: "100%", maxWidth: "640px" }} // Add this line
          height="564"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          title="Demo Video of VegifyIt Events"
        ></iframe>
      </section>
    </div>
  </>
);

export default EventsPage;
