import jwt from "jsonwebtoken";

const checkAuthz = (token, groups) => {
  if (!token) {
    return false;
  }
  if (!groups.length) {
    return true;
  }
  const intersection = jwt
    .decode(token)
    .user.groups.filter((groupName) => groups.includes(groupName));
  return !!intersection.length;
};
export default checkAuthz;
