import React from "react";
import { connect } from "react-redux";

const DeleteItem = (props) => {
  const { id, deleteFunction, dispatch } = props;

  const onClick = (e) => {
    if (window.confirm("Are you sure you want to delete") === true) {
      deleteFunction(id)(dispatch);
    }
  };
  return (
    <span onClick={() => onClick()}>
      <i class="fa fa-trash" aria-hidden="true" />
    </span>
  );
};

export default connect()(DeleteItem);
