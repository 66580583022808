import axios from "axios";

export const getRestaurantCuisines = async () => {
  const options = {
    method: "GET",
    url: "/api/restaurants/cuisines",
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
    return [];
  }
};

export const findMatch = async (name) => {
  const options = {
    method: "GET",
    url: "/api/restaurants/findMatch",
    params: { name },
  };
  try {
    return { isFound: true, restaurant: (await axios(options)).data };
  } catch (error) {
    return { isFound: false };
  }
};
