import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import { setAlert } from "../../../../actions/alert";
import { updateLocation } from "../../../../actions/currentLocation";
import { updateRestaurant } from "../../../../actions/restaurantBuilder";
import checkAuthz from "../../../../util/authz";
import CheckUnauth from "../../../auth/CheckUnauth";
const RestaurantWebsiteFinder = ({
  setAlert,
  updateRestaurant,
  currRestaurant,
  token,
  updateLocation,
  ...props
}) => {
  const initialFormData = {
    name: "",
    location: "",
    suggested: false,
    files: null,
  };
  const history = useHistory();
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [foundRestaurant, setFoundRestaurant] = useState({});
  const { name, files } = formData;

  useEffect(() => {
    if (props.history.location.search) {
      const query = new URLSearchParams(props.history.location.search);
      console.log(query);
      if (query.get("name")) {
        setFormData((oldData) => ({ ...oldData, name: query.get("name") }));
      }
    }
  }, [props.history.location.search]);

  const onFileInputChange = (e) => {
    console.log(e.target.files);
    setFormData({ ...formData, files: e.target.files });
  };

  // Add a reference to the file input
  const fileInputRef = React.useRef();

  // Add this function to handle the button click
  const onFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const lookupRestaurant = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("sending request");
    const url = name.replace(/(^\w+:|^)\/\//, "");
    try {
      const rest = await axios.get("/api/restaurant_builder/" + url);
      setFoundRestaurant(rest.data);
    } catch (e) {
      history.push({ pathname: "/create/v2/address", state: {} });
    }
    setLoading(false);
  };

  const submitOcrRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("sending request");

    try {
      const postData = new FormData();

      // Assuming `uploadedFiles` is an array of File objects
      postData.append("image", Array.from(files)[0]);

      const response = await axios.post(
        "/api/restaurant_builder/upload",
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFoundRestaurant(response.data);
    } catch (e) {
      console.log(e);
      history.push({ pathname: "/create/v2/address", state: {} });
    }
    setLoading(false);
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  if (Object.keys(foundRestaurant).length) {
    history.push({ pathname: "/create/v2/address", state: foundRestaurant });
  }
  return (
    <div className="container h-100 " style={{ flexGrow: 1 }}>
      <CheckUnauth {...props.location} />
      <div className="row create-container">
        <form className="form-container" onSubmit={(e) => lookupRestaurant(e)}>
          <div className="h3" style={{ fontSize: "2em" }}>
            Create a Restaurant
          </div>
          {/* <div className="form-label">
            How would you like to enter the restaurant's information?
          </div> */}
          <div style={{ width: "75vw", marginBottom: "20px" }}>
            <label className="form-label">Restaurant Website*</label>
            <div style={{ display: "flex" }}>
              <input
                style={{ width: "100%", fontSize: "2em" }}
                type="input"
                className="text-form"
                name="name"
                placeholder="www.vegifyit.com"
                pattern="(https?://)?(\w+\.)?(\w+\.)\S*"
                value={name}
                onChange={(e) => onChange(e)}
                title="a valid website url (e.g www.vegifyit.com)"
                required
              />
              <input
                style={{ alignSelf: "center" }}
                className="btn-primary"
                type="submit"
                disabled={loading || name.length === 0}
                value="Find Restaurant"
              />
            </div>
          </div>

          <div style={{ width: "75vw", marginBottom: "20px" }}>
            <input
              style={{ alignSelf: "center" }}
              onClick={() =>
                history.push({
                  pathname: "/create/v2/address",
                  state: { name },
                })
              }
              className="btn-primary"
              type="button"
              disabled={loading}
              value="No website / I don't know it"
            />
          </div>

          {checkAuthz(token, ["beta", "admin"]) && (
            <div style={{ width: "75vw", marginBottom: "20px" }}>
              <label className="form-label">
                Select image(s) of a menu for upload
              </label>
              <input
                onChange={onFileInputChange} // Handle the file input change
                type="file"
                // For now, only allow one image at a time
                // multiple
                accept="image/*"
                style={{ display: "none" }} // Hide the default file input
                ref={fileInputRef} // Add the reference to the file input
              />
              <button
                type="button"
                className="btn-primary"
                onClick={onFileButtonClick} // Trigger the file input when the button is clicked
              >
                <i class="fa-solid fa-camera"></i>
                {" Browse "}
              </button>
              <button
                type="button"
                className="btn-primary"
                onClick={submitOcrRequest} // Trigger the file input when the button is clicked
              >
                Submit image(s)
              </button>
              <div>
                {formData.files &&
                  Array.from(formData.files)
                    .map((file) => URL.createObjectURL(file))
                    .map((url, index) => (
                      <img
                        key={index}
                        src={url}
                        alt="Preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    ))}
              </div>
            </div>
          )}
          <BarLoader
            loading={loading}
            color="green"
            height="8px"
            cssOverride={{ margin: "16px" }}
          />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currRestaurant: state.restaurantBuilder.restaurant,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  updateRestaurant,
  updateLocation,
  setAlert,
})(RestaurantWebsiteFinder);
