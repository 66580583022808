import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../../actions/alert";
import {
  LeftDiv,
  SpaceApartDiv,
} from "../../restaurantBuilder/restaurantBuilderLegacy/RestaurantCreateBuild";

const PasswordRenameWidget = ({
  passwordSaveMethod,
  setAlert,
  buttonText,
  ...props
}) => {
  const [passwords, setPassword] = useState({
    password1: "",
    password2: "",
  });

  const email = new URLSearchParams(props.location.search).get("email");
  const { secretCode } = props.match.params;

  const valid = true;

  const register = async (e) => {
    e.preventDefault();
    if (passwords.password1 !== passwords.password2) {
      setAlert("Passwords must be the same!", "failure");
    } else if (passwords.password1.length < 8) {
      setAlert("Passwords must be at least 8 characters in length!", "failure");
    } else {
      passwordSaveMethod({
        email,
        secretCode,
        password: passwords.password1,
      });
    }
  };

  return valid ? (
    <div className="create-container">
      <form onSubmit={(e) => register(e)}>
        <div className="form-container">
          <LeftDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Email Address*</label>
              <input
                type="text"
                className="text-form"
                name="email"
                value={email}
                readOnly
              />
            </SpaceApartDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Password</label>
              <input
                type="password"
                className="text-form"
                name="password1"
                value={passwords.password1 || ""}
                onChange={(e) =>
                  setPassword({ ...passwords, password1: e.target.value })
                }
              />
            </SpaceApartDiv>
            <SpaceApartDiv>
              <label className="form-label-inline">Confirm Password</label>
              <input
                type="password"
                className="text-form"
                name="password2"
                value={passwords.password2 || ""}
                onChange={(e) =>
                  setPassword({ ...passwords, password2: e.target.value })
                }
              />
            </SpaceApartDiv>
            <input
              style={{
                textAlign: "center",
                alignSelf: "center",
                fontSize: "1.5em",
                height: "revert",
              }}
              className="btn-primary"
              type="submit"
              value={buttonText}
            />
          </LeftDiv>
        </div>
      </form>
    </div>
  ) : (
    <div>
      <p> Sorry that's not a valid code!</p>
    </div>
  );
};
const mapStateToProps = (state, props) => ({});
export default connect(mapStateToProps, { setAlert })(PasswordRenameWidget);
