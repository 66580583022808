import classNames from "classnames";
import getDistance from "geolib/es/getDistance";
import math from "mathjs";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import Rating from "react-rating";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "../../../App.css";
import "../../../Carousel.css";
import { updateMapCenter } from "../../../actions/currentLocation";
import {
  deleteRestaurant,
  hoverRestaurant,
  selectRestaurantFromList,
  unhoverRestaurant,
  unselectRestaurant,
} from "../../../actions/restaurants";
import ResultTitleFrame, { Column } from "./ResultTitleFrame";

const FlexParent = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
`;
const LeftContent = styled.div``;
const RightContent = styled.div`
  min-width: 6em;
  text-align: right;
`;
const RestaurantResult = ({
  restaurant,
  hoverRestaurant,
  unhoverRestaurant,
  selectRestaurantFromList,
  unselectRestaurant,
  currentLocation,
  selected,
  selectedSource,
  token,
  updateMapCenter,
  isOpen,
}) => {
  const onMouseEnter = () => {
    hoverRestaurant(restaurant._id);
  };
  const onMouseLeave = () => {
    unhoverRestaurant();
  };
  const thisRef = useRef(null);
  useEffect(() => {
    if (selected && selectedSource === "MAP") {
      // thisRef.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center",
      //   inline: "center",
      // });
      const mediaQuery = window.matchMedia("(max-width: 599px)");

      if (mediaQuery.matches) {
        // The viewport is 600px or less
        window.scrollTo({
          top: thisRef.current.offsetTop - 50,
          behavior: "smooth",
        });
      } else {
        // The viewport is more than 600px
        thisRef.current.parentNode.parentNode.scrollTop =
          thisRef.current.offsetTop - 50;
      }
    }
  }, [selected, selectedSource]);
  const distanceInMeters = getDistance(
    currentLocation,
    restaurant.geoJson.coordinates
  );
  const distance = math.unit(distanceInMeters, "m").to("mi");

  const onClick = selected
    ? () => {
        // unselectRestaurant();
        //  updateMapCenter(currentLocation)
      }
    : () => {
        selectRestaurantFromList(restaurant._id);
        updateMapCenter({
          lat: restaurant.geoJson.coordinates[1],
          lng: restaurant.geoJson.coordinates[0],
        });
      };

  const getOptionsCarousel = (restaurant) => {
    if (restaurant.veganOptions.length === 1) {
      const option = restaurant.veganOptions[0];
      return (
        <Column className={"result-option"} key={option.index}>
          <div
            style={{
              maxHeight: "3.6em",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {option.option}
            {option.description && (
              <span style={{ fontWeight: "normal", maxHeight: "3.6em" }}>
                : {option.description}
              </span>
            )}
          </div>
        </Column>
      );
    } else {
      const frames = [
        <ResultTitleFrame
          key={"123"}
          textArray={restaurant.veganOptions.map((option) => option.option)}
          detailsLink={
            <Link
              className={`${selected ? "" : "none"}`}
              to={{
                pathname: "/details/" + restaurant._id,
              }}
            >
              rest of menu
            </Link>
          }
        />,
        ...restaurant.veganOptions
          .filter(
            (option) => option.description && option.description.trim() !== ""
          )
          .slice(0, 6)
          .map((option, index) => (
            <Column className={"result-option"} key={index}>
              <div
                style={{
                  // maxHeight: "3.6em",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  padding: "0 0 25px",
                }}
              >
                {option.option}
                {option.description && (
                  <span style={{ fontWeight: "normal" }}>
                    : {option.description}
                  </span>
                )}
              </div>
            </Column>
          )),
      ];
      if (frames.length > 1) {
        return (
          <Carousel
            className={"custom"}
            showThumbs={false}
            showStatus={false}
            swipeable={false}
          >
            {frames}
          </Carousel>
        );
      } else {
        return frames[0];
      }
    }
  };
  return (
    <div
      className={`result-row ${isOpen ? "" : "none"}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      key={restaurant._id}
      ref={thisRef}
    >
      <FlexParent
        restaurant={restaurant}
        key={restaurant._id}
        className={classNames("result", { selected: selected })}
        ref={thisRef}
      >
        <LeftContent>
          {" "}
          {restaurant.name}
          <div className="result-body">
            {restaurant.cuisine
              .map((string) => string.replace(/\b\w/g, (l) => l.toUpperCase()))
              .join(", ")}
          </div>
        </LeftContent>

        <RightContent>
          {" "}
          {token && (
            <div className="admin-option">
              <Link
                to={{
                  pathname: "/" + restaurant._id + "/edit",
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <i class="fas fa-pencil-alt"></i>
              </Link>
              {/* <DeleteItem id={restaurant._id} deleteFunction={deleteRestaurant} /> */}
            </div>
          )}
          {math.format(distance, { notation: "fixed", precision: 2 })}
          <br />
          {restaurant.rating && (
            <Rating
              emptySymbol="far fa-star fa-xs"
              fullSymbol="fas fa-star fa-xs"
              initialRating={restaurant.rating}
              readonly={true}
            />
          )}
        </RightContent>
      </FlexParent>
      <div className={`selected result-body ${selected ? "" : "none"}`}>
        {getOptionsCarousel(restaurant)}
      </div>
      <div
        className={selected ? "selected" : ""}
        style={{ fontSize: ".5em", flexBasis: "100%", flexGrow: 1 }}
      >
        {selected ? "" : "See more"}
      </div>
      <Link
        className={`${selected ? "" : "none"}`}
        to={{
          pathname: "/details/" + restaurant._id,
        }}
      >
        see details
      </Link>
    </div>
  );
};

RestaurantResult.propTypes = {
  currentLocation: PropTypes.object.isRequired,
};
const mapStateToProps = (state, props) => ({
  currentLocation: state.currentLocation,
  selected: state.restaurant.selected === props.restaurant._id,
  selectedSource: state.restaurant.source,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  hoverRestaurant,
  unhoverRestaurant,
  selectRestaurantFromList,
  unselectRestaurant,
  deleteRestaurant,
  updateMapCenter,
})(RestaurantResult);
