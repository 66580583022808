import { combineReducers } from "redux";
import alerts from "./alerts";
import auth from "./auth";
import currentLocation from "./currentLocation";
import mapCenter from "./mapCenter";
import restaurantBuilder from "./restaurantBuilder";
import restaurant from "./restaurants";
import results from "./results";
import selectedCuisines from "./selectedCuisines";

export default combineReducers({
  restaurant,
  currentLocation,
  restaurantBuilder,
  auth,
  alerts,
  mapCenter,
  selectedCuisines,
  results,
});
