import React from "react";
import { Helmet } from "react-helmet";

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    // maxWidth: "calc(100%-40px)",
    margin: "20px auto",
    padding: "20px",
    flexGrow: 1,
  },
  header: {
    textAlign: "center",
    color: "#2E7D32",
  },
  section: {
    margin: "20px 0",
  },
  subsection: {
    marginBottom: "15px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "none",
  },
  highlight: {
    fontWeight: "bold",
  },
};

const ConsultingSection = () => (
  <>
    <Helmet>
      <title>
        Expand Your Menu with Vegan Options | VegifyIt Consulting Services
      </title>
      <meta
        name="description"
        content="VegifyIt helps restaurants diversify their menus with plant-based options. Discover our vegan menu consulting services today."
      />
    </Helmet>
    <div style={styles.container}>
      <h2 style={styles.header}>
        Transform Your Menu with Plant-Based Options
      </h2>
      <p>
        In the rapidly growing market for vegan and plant-based dining,
        restaurants are finding a competitive edge by offering diverse vegan
        options. VegifyIt is at the forefront of this transformation, offering
        expert consulting services to help your restaurant tap into this
        lucrative market. Whether your cuisine is traditional, ethnic, or
        fusion, our team specializes in creating vegan dishes that align with
        your brand and appeal to a broad audience.
      </p>
      <p>
        Joining VegifyIt not only puts your restaurant on the map for conscious
        consumers but also enhances your brand's reputation for inclusivity and
        innovation. With our expertise, you can seamlessly integrate plant-based
        dishes into your menu, attracting a new wave of customers and setting
        your establishment apart.
      </p>
      <p>
        Interested in making your menu more inclusive and appealing to a wider
        audience? Contact us for a free initial consultation to explore the
        potential of plant-based cuisine tailored to your restaurant's unique
        style.
      </p>
      <p>
        For more information or to schedule your consultation, please email{" "}
        <a href="mailto:harrison@vegifyit.com?subject=Interest in VegifyIt Consulting Services">
          harrison@vegifyit.com
        </a>{" "}
        or call/text us at 518-961-5215.
      </p>
    </div>
  </>
);

export default ConsultingSection;
