import axios from "axios";

export const backendLog = async (message) => {
  const options = {
    method: "POST",
    url: "/api/restaurants/logging",
    data: { message },
  };
  try {
    await axios(options);
  } catch (error) {}
};
