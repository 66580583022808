import axios from "axios";

export const getMissingGoogleIds = async () => {
  const options = {
    method: "GET",
    url: "/api/restaurantDetails/missingGoogleId",
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
  }
};

export const updateRestaurant = async ({ id, ...fields }) => {
  const options = {
    method: "PUT",
    url: "/api/restaurants/" + id,
    data: fields,
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
  }
};

export const mailingListSignup = async ({
  name,
  email,
  location,
  city,
  recaptchaToken,
}) => {
  const options = {
    method: "POST",
    url: "/api/users/mailinglist",
    data: { email, name, location, city, recaptchaToken },
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    return error;
  }
};

export const mailingListSecretCode = async ({ email }) => {
  const options = {
    method: "PUT",
    url: `/api/users/mailinglist/${email}`,
  };
  return (await axios(options)).data;
};

export const getMailingList = async () => {
  const options = {
    method: "GET",
    url: `/api/users/mailinglist`,
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

export const getMailingListFiltered = async ({ lat, long }) => {
  const options = {
    method: "GET",
    url: `/api/users/mailinglist?distance=30&lat=${lat}&long=${long}`,
  };
  try {
    return (await axios(options)).data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};
