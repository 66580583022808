import {
  RESULTS_RECEIVED,
  RESTAURANT_HOVER,
  RESTAURANT_UNHOVER,
  RESTAURANT_SELECT,
  RESTAURANT_UNSELECT,
  RESULTS_UPDATE,
  RESTAURANT_DELETE,
  NO_DATA,
} from "../actions/types";

const initialState = { restaurants: [] };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESULTS_UPDATE:
    case RESULTS_RECEIVED:
      return { ...state, restaurants: payload };
    case RESTAURANT_DELETE:
      console.log(payload);
      console.log(state);
      const restaurants = state.restaurants.filter(
        (restaurant) => restaurant._id !== payload
      );
      return { ...state, restaurants };

    case RESTAURANT_HOVER:
      return { ...state, hover: payload };
    case RESTAURANT_UNHOVER:
      return { ...state, hover: null };
    case RESTAURANT_SELECT:
      return { ...state, selected: payload.id, source: payload.source };
    case RESTAURANT_UNSELECT:
      return { ...state, selected: null };
    case NO_DATA:
      return { ...state, noData: payload };
    default:
      return state;
  }
}
