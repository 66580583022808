import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { updateRestaurant } from "../../../../actions/restaurantBuilder";
import { findMatch } from "../../../../util/getRestaurantCuisines";

const RestaurantCreateMatch = ({ currRestaurant, updateRestaurant }) => {
  const [restaurantData, setRestaurant] = useState({
    searching: true,
    matched: false,
  });
  const { restaurant, isFound, searching, matched } = restaurantData;

  useEffect(() => {
    async function findRestaurant() {
      if (currRestaurant) {
        const foundRestaurant = await findMatch(currRestaurant.name);
        setRestaurant({
          restaurant: foundRestaurant.restaurant,
          isFound: foundRestaurant.isFound,
          searching: false,
        });
      }
    }
    findRestaurant();
  }, [currRestaurant]);

  console.log(isFound);
  console.log(restaurant);

  if (currRestaurant === null) {
    return <Redirect to="/create/search" />;
  }
  if (searching) {
    return <div> SEARCHING </div>;
  }
  if (matched || (!searching && !isFound)) {
    return <Redirect to="/create/build" />;
  }
  if (!searching && isFound) {
    return (
      <div className="create-container">
        <div>
          <div>{`Found a restaurant matching name: ${currRestaurant.name}`}</div>
          <div>
            With Cuisine:
            {restaurant.cuisine
              .map((string) => string.replace(/\b\w/g, (l) => l.toUpperCase()))
              .join(", ")}
          </div>
          <div>
            With Vegan Options:
            {restaurant.veganOptions.map((option) => (
              <div key={option.index}>
                &nbsp;&nbsp;&nbsp;&nbsp;{option.option}: {option.description}
              </div>
            ))}
          </div>
          <div> Would you like to clone this data? </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <input
              style={{ width: "33%" }}
              className="btn-primary"
              type="button"
              value="Yes"
              onClick={() => {
                updateRestaurant({
                  ...currRestaurant,
                  veganOptions: restaurant.veganOptions.map((option) => {
                    return {
                      ...option,
                      name: option.option,
                      desc: option.description,
                    };
                  }),
                  cuisine: restaurant.cuisine.reduce(
                    (result, item) => (result = { ...result, [item]: item }),
                    {}
                  ),
                });
                setRestaurant({ ...restaurantData, matched: true });
              }}
            />
            <input
              style={{ width: "33%" }}
              className="btn-primary"
              type="button"
              value="No"
              onClick={() => {
                setRestaurant({ ...restaurantData, matched: true });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};
const mapStateToProps = (state) => ({
  currRestaurant: state.restaurantBuilder.restaurant,
  token: state.auth.token,
});
export default connect(mapStateToProps, { updateRestaurant })(
  RestaurantCreateMatch
);
